import dayjs from 'dayjs';
import { atom } from 'recoil';

export const touchUIState = atom({
  key: 'touchUI',
  default: false,
});

export const userState = atom({
  key: 'userState',
  default: {
    isLoggedIn: false,
    accessToken: null,
  },
});

export const myInfoState = atom({
  key: 'myInfo',
  default: {
    userId: 0,
    userName: '',
    loginId: '',
    contact: '',
    email: '',
    roleCode: '',
    roleName: '',
    userPosition: '',
    associationInfo: {
      associationId: null,
      associationCode: null,
      associationName: null,
    },
    enterpriseInfo: {
      enterpriseId: null,
      enterpriseCode: null,
      enterpriseName: null,
    },
    shopInfo: {
      shopId: null,
      shopCode: null,
      shopName: null,
    },
  },
});

export const perfomanceCheckState = atom({
  key: 'perfomance',
  default: null,
});

export const claimSearchState = atom({
  key: 'claimSearchState',
  default: {
    startDate: dayjs('20240701').toDate(),
    endDate: dayjs().toDate(),
    shopId: null,
    carNbr: null,
    customerName: null,
    customerContact: null,
    checkType: null,
    receiptName: null,
    completeName: null,
    orderType: null,
  },
});

export const searchSaveState = atom({
  key: 'searchSave',
  default: false,
});

export const claimOldSearchState = atom({
  key: 'claimOldSearchState',
  default: {
    startDate: dayjs('20240701').toDate(),
    endDate: dayjs().toDate(),
    shopId: null,
    carNbr: null,
    customerName: null,
    customerContact: null,
    checkType: null,
    receiptName: null,
    completeName: null,
    orderType: null,
  },
});

export const searchOldSaveState = atom({
  key: 'searchOldSave',
  default: false,
});

export const shopListState = atom({
  key: 'shopList',
  default: [],
});
