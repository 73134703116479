import axios from 'axios';

const BASE_URI = '/apis/settlement/safe6';

export const Settlement = {
  /**
   * 매매상사별 월정산내역
   * @param {object} data
   * @returns
   */
  getCompanies: async (data) => {
    const res = await axios.get(`${BASE_URI}/companies`, { params: data });
    return res.data.data;
  },

  /**
   * 매매상사 각 월정산 세부내역
   * @param {object} data
   * @returns
   */
  getMonthCustomerCompany: async (data) => {
    const { settlementMonth, customerCompanyId, page, size } = data;
    const res = await axios.get(
      `${BASE_URI}/companies/list/${settlementMonth}/${customerCompanyId}`,
      {
        params: {
          page: page,
          size: size,
        },
      }
    );
    return res.data.data;
  },

  /**
   * 매매상사별 일별 정산내역
   * @param {object} data
   * @returns
   */
  getCompany: async (data) => {
    const res = await axios.get(`${BASE_URI}/company`, { params: data });
    return res.data.data;
  },

  /**
   * 매매상사 각 일정산 세부내역
   * @param {object} data
   * @returns
   */
  getDayCustomerCompany: async (data) => {
    const { settlementDay, page, size } = data;
    const res = await axios.get(`${BASE_URI}/company/list/${settlementDay}`, {
      params: {
        page: page,
        size: size,
      },
    });
    return res.data.data;
  },

  uploadExcel: async (data, files) => {
    if (!files.length) return console.log('파일 없음');
    const formData = new FormData();

    if (data) formData.append('settleMonth', data);

    if (files && files.length > 0) {
      files.forEach((file) => {
        formData.append('file', file);
      });
    }

    // try {
    const res = await axios.post(`${BASE_URI}/excel`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
    // } catch (e) {
    // return e;
    // }
  },
};

export default Settlement;
