import React from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { Dialog } from 'primereact/dialog';

const Postcode = ({ open = false, onHide = null, onComplete = null }) => {
  return (
    <Dialog
      modal
      breakpoints={{ '960px': '60vw' }}
      header="주소검색"
      style={{ width: '40vw' }}
      contentClassName="p-3"
      visible={open}
      onHide={onHide}
    >
      <DaumPostcodeEmbed onComplete={onComplete} />
    </Dialog>
  );
};

export default Postcode;
