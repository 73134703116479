import { CommonUtil } from '../utils/commonUtil';
import _ from 'lodash';
import dayjs from 'dayjs';

const CERTIFICATE_DATAS = [
  {
    name: 'inspectorName',
    objName: 'inspector',
    fieldName: 'enterpriseName',
    title: '점검업체이름',
    insuranceCompanyCode: ['08', '13', '69'], // DB, 캐롯
    pos: {
      // insuranceCode: [top, left]
      '08': [535, 198],
      // 10: [235, 158],
      13: [323, 230],
      69: [258, 465],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'inspector.enterpriseName');
    },
  },
  {
    name: 'shopName',
    objName: 'inspector',
    fieldName: 'shopName',
    title: '점검장이름',
    insuranceCompanyCode: ['01', '10', '13', '69'], // DB
    pos: {
      '01': [260, 498],
      10: [358, 148],
      13: [323, 470],
      69: [258, 515],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'inspector.shopName');
    },
  },
  {
    name: 'shopName_2',
    objName: 'inspector',
    fieldName: 'shopName_2',
    title: '점검장이름',
    insuranceCompanyCode: ['10'], // DB
    pos: {
      10: [380, 148],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'inspector.shopName');
    },
  },
  {
    name: 'VIN',
    objName: 'checkData',
    fieldName: 'VIN',
    title: '차대번호전체',
    insuranceCompanyCode: ['01', '10', '13', '69'], // DB, 캐롯
    pos: {
      '01': [278, 475],
      10: [245, 525],
      13: [345, 150],
      69: [278, 465],
    },
    getValue: (totalValues) => {
      if (_.get(totalValues, 'checkData.VIN').length === 17) {
        return _.get(totalValues, 'checkData.VIN');
      } else {
        return (
          _.get(totalValues, 'vehicleInformation.VIN') +
          _.get(totalValues, 'vehicleInformation.vinRemain')
        );
      }
    },
  },
  {
    name: 'CHCK_ENTRPS_BIZ_NO',
    objName: 'checkData',
    fieldName: 'CHCK_ENTRPS_BIZ_NO',
    title: '사업자번호',
    insuranceCompanyCode: ['08', '10'], // DB, 캐롯
    pos: {
      '08': [535, 505],
      10: [358, 238],
    },
    getValue: (totalValues) => {
      return CommonUtil.Formatter.biz(
        totalValues?.checkData?.CHCK_ENTRPS_BIZ_NO
      );
    },
  },
  {
    name: 'CHCK_ENTRPS_BIZ_NO_2',
    objName: 'checkData',
    fieldName: 'CHCK_ENTRPS_BIZ_NO_2',
    title: '사업자번호',
    insuranceCompanyCode: ['10'], // DB, 캐롯
    pos: {
      10: [380, 238],
    },
    getValue: (totalValues) => {
      return CommonUtil.Formatter.biz(
        totalValues?.checkData?.CHCK_ENTRPS_BIZ_NO
      );
    },
  },
  {
    name: 'statementNumber',
    objName: 'checkData',
    fieldName: 'PRFOMNC_CHCK_NO',
    title: '성능점검번호',
    insuranceCompanyCode: ['13'], // DB
    pos: {
      13: [345, 400],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'checkData.PRFOMNC_CHCK_NO');
    },
  },
  {
    name: 'CNM',
    objName: 'vehicleInformation',
    fieldName: 'CNM',
    title: '차량명',
    insuranceCompanyCode: ['10'], //KB
    pos: {
      10: [266, 148],
    },
    getValue: (totalValues) => {
      return `${_.get(totalValues, 'vehicleInformation.CNM')}${_.get(
        totalValues,
        'vehicleInformation.cnmDetail'
      )}`;
    },
  },
  {
    name: 'CHCK_DE',
    objName: 'checkData',
    fieldName: 'CHCK_DE',
    title: '성능점검일자',
    insuranceCompanyCode: ['10', '13'], // KB, DB
    pos: {
      10: [266, 525],
      13: [345, 610],
    },
    getValue: (totalValues) => {
      return dayjs(_.get(totalValues, 'checkData.CHCK_DE')).format(
        'YYYY-MM-DD'
      );
    },
  },

  {
    name: 'VHRNO',
    objName: 'checkData',
    fieldName: 'VHRNO',
    title: '차량번호',
    insuranceCompanyCode: ['01', '08', '10', '13', '69'], // KB, DB, 캐롯
    pos: {
      '01': [278, 155],
      '08': [565, 505],
      10: [245, 148],
      13: [365, 150],
      69: [278, 155],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'checkData.VHRNO');
    },
  },

  {
    name: 'insurancePolicyNumber',
    objName: 'paymentInformation',
    fieldName: 'insurancePolicyNumber',
    title: '보험증권번호',
    insuranceCompanyCode: ['08', '10', '13'], // DB
    pos: {
      '08': [565, 198],
      10: [317, 148],
      13: [365, 400],
    },
    getValue: (totalValues) => {
      return _.get(totalValues, 'paymentInformation.insurancePolicyNumber');
    },
  },

  {
    name: 'paidInsurancePriceAmount',
    objName: 'paymentInformation',
    fieldName: 'paidInsurancePriceAmount',
    title: '보험료',
    insuranceCompanyCode: ['13'], // DB
    pos: {
      13: [365, 610],
    },
    getValue: (totalValues) => {
      // return `${CommonUtil.Unit.format(
      //   _.get(totalValues, 'paymentInformation.paidInsurancePriceAmount') +
      //     _.get(totalValues, 'paymentInformation.totalInsurancePrice')
      // )}원`;
      return `${CommonUtil.Unit.format(
        _.get(totalValues, 'paymentInformation.paidInsurancePriceAmount')
      )}원`;
    },
  },
  {
    name: 'insuranceType',
    title: '보험종목',
    insuranceCompanyCode: ['69'], // 캐롯
    pos: {
      69: [258, 155],
    },
    getValue: (totalValues) => {
      return '자동차 성능∙상태점검 책임보험';
    },
  },
  {
    name: 'customerServiceCenterNumber',
    title: '상담번호',
    insuranceCompanyCode: ['10', '13'], // KB, DB
    pos: {
      10: [],
      13: [],
    },
    getValue: (totalValues) => {
      const numbers = {
        10: '',
        13: '1600-3528',
      };
      return _.get(
        numbers,
        _.get(totalValues, 'paymentInformation.insuranceCompany')
      );
    },
  },
];

export { CERTIFICATE_DATAS };
