import * as Button from './../../components/Atoms/Button';
import * as Modal from './../../components/Atoms/Modal';
import _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import { Panel } from 'primereact/panel';
import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { compressImages } from '../../utils/compressUtil';
import FileState from '../../enums/FileState';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import { useParams } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Claim from '../../services/ClaimService';
import { ToggleButton } from 'primereact/togglebutton';
import DocsViewer from '../Common/Claim/DocsViewer';

import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';

const PartnerDocument = ({
  fileItems,
  setFileItems,
  deleteImgs,
  setDeleteImgs,
  copyFile,
  carNbr,
  checked,
  onToggle,
  selectedPartner,
  setSelectedPartner,
  setShowSmsType,
  setShowSmsModal,
  setSmsData,
  smsData,
  postPushMessage,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [curTitle, setCurTitle] = useState('');
  const [loading, setLoading] = useState(false);
  let { dealer, partner, aI, pId } = useParams();
  const dealerUser = dealer?.split('=')[1] === 'true' ? true : false;
  const partnerUser = partner?.split('=')[1] === 'true' ? true : false;
  const aId = aI?.split('=')[1];
  const partnerId = Number(pId?.split('=')[1]);
  const [curImg, setCurImg] = useState('');
  const [curPartner, setCurPartner] = useState([]);
  const [curPartnerId, setCurParnterId] = useState('');
  useEffect(() => {
    if (partnerId && !!selectedPartner.length) {
      setCurPartner([selectedPartner.find((el) => el.partnerId === partnerId)]);
    }
  }, [selectedPartner, partnerId]);

  useEffect(() => {
    let files = [];
    if (!!curPartner && partnerId) {
      curPartner.map((el) => el.partnerDocuments.map((doc) => files.push(doc)));
      setFileItems(files);
    }
  }, [curPartner, partnerId]);
  const filteredSelectPartner = selectedPartner.filter(
    (partner) => partner.useYn === 'Y'
  );

  const P_CHECK = useRef(null);
  const P_FRONT = useRef(null);
  const P_DASHBOARD = useRef(null);
  const P_LIST = useRef(null);
  const P_OPINION = useRef(null);
  const P_PART = useRef(null);
  const P_MAINTENANCE = useRef(null);
  const P_REGISTRATION = useRef(null);
  const P_BANKBOOK = useRef(null);
  const P_COMPLETED = useRef(null);
  const P_INSURANCE = useRef(null);
  const P_ETC = useRef(null);
  const P_APPROVED = useRef(null);

  const deleteFile = (e) => {
    const imgId = e.target.alt ? Number(e.target.alt) : Number(e.target.id);
    const partner = selectedPartner.find((el) => el.partnerId === curPartnerId);
    const imgFiles = partner.partnerDocuments;
    if (!!imgId) {
      const trashFile = imgFiles.filter((el) => el.imgId === imgId);
      const deletedList = imgFiles.filter((el) => el.imgId !== imgId);
      trashFile.map((el) =>
        deletedList.push({
          imgId: el.imgId,
          documentCode: el.documentCode,
          state: 'D',
        })
      );
      setDeleteImgs((ps) => [...ps, ...trashFile]);
      return (partner.partnerDocuments = deletedList);
    } else {
      const name = e.target.alt ? e.target.alt : e.target.id;
      const filteredFile = imgFiles.filter(
        (el) => !(!el?.imgId && el.file.name === name)
      );
      const deletedList = fileItems.filter((el) => el?.name !== name);
      setFileItems(deletedList);
      return (partner.partnerDocuments = filteredFile);
    }
  };

  const filterDocuments = (partnerDocuments, documentCode) => {
    return partnerDocuments?.filter((doc) => doc.documentCode === documentCode);
  };

  const [downloadLoading, setDownloadLoading] = useState(false);
  const saveFilesAsZip = async (el, type) => {
    setDownloadLoading(true);
    const partnerName = el?.partnerName;
    const checkImg = filterDocuments(el?.partnerDocuments, 'P_CHECK');
    const frontImg = filterDocuments(el?.partnerDocuments, 'P_FRONT');
    const dashImg = filterDocuments(el?.partnerDocuments, 'P_DASHBOARD');
    const listImg = filterDocuments(el?.partnerDocuments, 'P_LIST');
    const optionImg = filterDocuments(el?.partnerDocuments, 'P_OPINION');
    const partImg = filterDocuments(el?.partnerDocuments, 'P_PART');
    const maintenanceImg = filterDocuments(
      el?.partnerDocuments,
      'P_MAINTENANCE'
    );
    const registerImg = filterDocuments(el?.partnerDocuments, 'P_REGISTRATION');
    const bankImg = filterDocuments(el?.partnerDocuments, 'P_BANKBOOK');
    const completeImg = filterDocuments(el?.partnerDocuments, 'P_COMPLETED');
    const insuranceImg = filterDocuments(el?.partnerDocuments, 'P_INSURANCE');
    const etcImg = filterDocuments(el?.partnerDocuments, 'P_ETC');
    const approvedImg = filterDocuments(el?.partnerDocuments, 'P_APPROVED');
    const Dfiles = [
      ...checkImg,
      ...frontImg,
      ...dashImg,
      ...listImg,
      ...optionImg,
    ];
    const Rfiles = [
      ...maintenanceImg,
      ...partImg,
      ...registerImg,
      ...bankImg,
      ...completeImg,
      ...insuranceImg,
      ...etcImg,
      ...approvedImg,
    ];

    const files = type === '진단' ? Dfiles : Rfiles;

    const folderName = `${partnerName}_${carNbr}_${type}서류`;
    const haveIds = [];
    const findFile = files.find((el) => el.state === 'I');
    files.map((el) => (el.imgId ? haveIds.push(el.imgId) : null));

    if (!files.length) return window.cerp.toast.warn('서류가 없습니다.');
    if (!haveIds.length || !!findFile)
      return window.cerp.toast.warn('클레임 저장 후 가능합니다.');
    const zip = new JSZip();
    const imagesFolder = zip.folder(folderName);
    const imagePromises = files.map(async (file, index) => {
      const imgBlob = await Claim.getBlobFromUrl(file.filePath);
      imagesFolder.file(
        `${file.originalFileName.split('.')[0]}_${index + 1}.jpg`,
        imgBlob,
        {
          binary: true,
        }
      );
    });
    await Promise.all(imagePromises);
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${folderName}.zip`);
    });
    setDownloadLoading(false);
    window.cerp.toast.info('다운로드가 완료되었습니다.');
  };

  const elementRefs = {
    P_CHECK,
    P_FRONT,
    P_DASHBOARD,
    P_OPINION,
    P_LIST,
    P_PART,
    P_MAINTENANCE,
    P_REGISTRATION,
    P_BANKBOOK,
    P_INSURANCE,
    P_COMPLETED,
    P_ETC,
    P_APPROVED,
  };

  const handleClick = (e) => {
    const docType = DOC_TYPE.find((doc) => doc.label === e.target.id);
    if (docType && elementRefs[docType.value]) {
      elementRefs[docType.value].current.click();
    }
  };

  const uploadFiles = async (acceptedFiles, title, partnerId) => {
    setLoading(true);
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    const fileList = Object.values(acceptedFiles);
    fileList.map((file) =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );

    const compressedImages = await compressImages(fileList, options);
    compressedImages.forEach((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
    });

    const code = DOC_TYPE.find((el) => el.label === title).value;
    const partCodeAttachedFiles = _.reduce(
      compressedImages,
      (res, v) => {
        res.push({
          documentCode: code,
          file: v,
          state: FileState.Inserted,
        });
        return res;
      },
      []
    );

    const { fileData, files } = CommonUtil.File.convertForServerClaimPartner(
      partCodeAttachedFiles
    );

    const partner = filteredSelectPartner.find(
      (el) => el.partnerId === partnerId
    );

    if (partner?.partnerDocuments) {
      partCodeAttachedFiles.map((el) => partner?.partnerDocuments.push(el));
    }

    setFileItems((ps) => [...ps, ...files]);
    setLoading(false);
    window.cerp.toast.info(
      '업로드 성공',
      '저장 버튼을 클릭하여 저장해 주세요.'
    );
  };

  return (
    <>
      <Panel
        header={
          <div className="flex justify-content-between align-items-center">
            <div>5. 입고점검 및 수리</div>
            <div className="flex gap-2">
              {!dealerUser && !partnerUser && !aId ? (
                <>
                  <Button.Default
                    label="리콜 여부 조회 바로가기"
                    icon="pi pi-external-link"
                    iconPos="right"
                    type={'button'}
                    onClick={(e) => {
                      e.preventDefault();
                      CommonUtil.Window.popup(
                        'https://www.car.go.kr/home/main.do',
                        '리콜 여부 조회',
                        500,
                        700
                      );
                    }}
                  />
                </>
              ) : !dealerUser || !partnerUser ? null : null}
            </div>
          </div>
        }
        className="pt-3 sm:text-base text-2xl"
      >
        <BlockUI
          template={
            loading &&
            dealerUser !== undefined && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="grid p-2">
            {!!filteredSelectPartner?.length ? (
              filteredSelectPartner?.map((el, idx) => {
                const checkImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_CHECK'
                );
                const frontImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_FRONT'
                );
                const dashImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_DASHBOARD'
                );
                const listImg = filterDocuments(el?.partnerDocuments, 'P_LIST');
                const optionImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_OPINION'
                );
                const partImg = filterDocuments(el?.partnerDocuments, 'P_PART');
                const maintenanceImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_MAINTENANCE'
                );
                const registerImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_REGISTRATION'
                );
                const bankImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_BANKBOOK'
                );
                const completeImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_COMPLETED'
                );
                const insuranceImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_INSURANCE'
                );
                const etcImg = filterDocuments(el?.partnerDocuments, 'P_ETC');
                const approvedImg = filterDocuments(
                  el?.partnerDocuments,
                  'P_APPROVED'
                );

                return (
                  <React.Fragment key={idx}>
                    <div
                      style={{
                        display: `${
                          partnerUser && partnerId !== el.partnerId
                            ? 'none'
                            : ''
                        }`,
                      }}
                      className={
                        'col-12 border-1 border-gray-300 border-round mb-2'
                      }
                      onClick={() =>
                        setCurParnterId(
                          partnerUser && !!partnerId ? partnerId : el.partnerId
                        )
                      }
                    >
                      <div className="flex align-items-center font-bold text-primary p-2">
                        [{el?.partnerName}]
                      </div>

                      <div className="w-full flex justify-content-between align-items-center p-2 mb-2">
                        <Calendar
                          value={
                            el?.receiptDate &&
                            typeof el?.receiptDate === 'string'
                              ? CommonUtil.Formatter.stringToDate(
                                  el?.receiptDate
                                )
                              : el?.receiptDate || ''
                          }
                          onChange={(e) => {
                            const updatedSelectedPartner =
                              filteredSelectPartner.map((item) => {
                                if (item.partnerId === el.partnerId) {
                                  return {
                                    ...item,
                                    receiptDate: e.value,
                                  };
                                }
                                return item;
                              });
                            setSelectedPartner(updatedSelectedPartner);
                          }}
                          dateFormat="yy-mm-dd"
                          showButtonBar
                          showIcon
                          mask="9999-99-99"
                          placeholder="입고일자 선택"
                          id={el.partnerId}
                          name="receiptDate"
                        />
                        <ToggleButton
                          checked={
                            checked?.find((el) => el.status === 'Z06')?.value
                          }
                          onChange={(e) => onToggle('Z06', e.value)}
                          onLabel="완료"
                          offLabel="미확인"
                          onIcon="pi pi-flag-fill"
                          offIcon="pi pi-flag"
                        />
                      </div>

                      <div className="field grid mb-0 p-2 align-items-center">
                        <div className="lg:col-3 md:col-6 col-12 ">
                          <DocsViewer
                            title="입고 차량전면사진"
                            imgFiles={frontImg}
                            buttonName={'P_FRONT' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_FRONT}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '입고 차량전면사진',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12">
                          <DocsViewer
                            title="입고 계기판사진"
                            imgFiles={dashImg}
                            buttonName={'P_DASHBOARD' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_DASHBOARD}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '입고 계기판사진',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12">
                          <DocsViewer
                            title="점검사진"
                            imgFiles={checkImg}
                            buttonName={'P_CHECK' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_CHECK}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '점검사진',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12">
                          <DocsViewer
                            title="부품명세서"
                            imgFiles={partImg}
                            buttonName={'P_PART' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_PART}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '부품명세서',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12">
                          <DocsViewer
                            title="소견서"
                            imgFiles={optionImg}
                            buttonName={'P_OPINION' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_OPINION}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '소견서',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12">
                          <DocsViewer
                            title="견적서"
                            imgFiles={listImg}
                            buttonName={'P_LIST' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_LIST}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '견적서',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>{' '}
                      </div>

                      {(!dealerUser || !partnerUser) &&
                      aId &&
                      partnerId ? null : (
                        <div className="col px-2">
                          <div className="flex justify-content-end  align-items-center h-3rem">
                            <Button.Default
                              className="p-button-outlined"
                              icon="pi pi-download"
                              label="다운로드"
                              onClick={() => saveFilesAsZip(el, '진단')}
                              loading={downloadLoading}
                            />
                          </div>
                        </div>
                      )}

                      <Divider />

                      {partnerId ? null : (
                        <>
                          <div className="flex justify-content-between md:flex-row flex-column md:align-items-center w-full">
                            <div className="p-2 text-base flex align-items-center">
                              <div className="font-bold sm:text-base text-2xl mr-4 md:mr-5 lg:mr-8">
                                수리 여부
                              </div>
                              <RadioButton
                                inputId={'workY' + el.partnerId}
                                name="customerSearchBy"
                                value="I"
                                className="mr-2"
                                onChange={(e) => {
                                  const updatedSelectedPartner =
                                    filteredSelectPartner.map((item) => {
                                      if (item.partnerId === el.partnerId) {
                                        return {
                                          ...item,
                                          claimStatusCode: e.value,
                                        };
                                      }
                                      return item;
                                    });
                                  setSelectedPartner(updatedSelectedPartner);
                                }}
                                checked={el.claimStatusCode === 'I'}
                              />
                              <label htmlFor={'workY' + el.partnerId}>
                                수리 진행
                              </label>
                              <RadioButton
                                inputId={'workN' + el.partnerId}
                                name="customerSearchBy"
                                value="N"
                                className="ml-4 mr-2"
                                onChange={(e) => {
                                  const updatedSelectedPartner =
                                    filteredSelectPartner.map((item) => {
                                      if (item.partnerId === el.partnerId) {
                                        return {
                                          ...item,
                                          claimStatusCode: e.value,
                                        };
                                      }
                                      return item;
                                    });
                                  setSelectedPartner(updatedSelectedPartner);
                                }}
                                checked={el.claimStatusCode === 'N'}
                              />
                              <label htmlFor={'workN' + el.partnerId}>
                                미수리
                              </label>
                              <RadioButton
                                inputId={'workC' + el.partnerId}
                                name="customerSearchBy"
                                value="C"
                                className="ml-4 mr-2"
                                onChange={(e) => {
                                  const updatedSelectedPartner =
                                    filteredSelectPartner.map((item) => {
                                      if (item.partnerId === el.partnerId) {
                                        return {
                                          ...item,
                                          claimStatusCode: e.value,
                                        };
                                      }
                                      return item;
                                    });
                                  setSelectedPartner(updatedSelectedPartner);
                                }}
                                checked={el.claimStatusCode === 'C'}
                              />
                              <label htmlFor={'workC' + el.partnerId}>
                                당사면책
                              </label>
                            </div>
                            <div className="">
                              <DocsViewer
                                title="승인견적서"
                                imgFiles={approvedImg}
                                buttonName={'P_APPROVED' + el.partnerId}
                                setCurTitle={setCurTitle}
                                setShowModal={setShowModal}
                                curImg={curImg}
                                setCurImg={setCurImg}
                                deleteFile={deleteFile}
                                handleClick={handleClick}
                                // userType={true}
                                upload={true}
                              />
                            </div>
                            <div className="pr-2">
                              {/* <Button.Default
                                className="p-button-outlined"
                                icon="pi pi-mobile"
                                label="승인견적서 전송"
                                onClick={() => postPushMessage('P')}
                                disabled
                              /> */}
                              <Button.Default
                                label="수리사진 업로드 요청"
                                icon="pi pi-comment"
                                className="p-button-outlined"
                                onClick={() => {
                                  setShowSmsModal(true);
                                  setShowSmsType('PARTNER_UPLOAD_URL');
                                  setSmsData({
                                    ...smsData,
                                    partnerInfo: el,
                                    destAddr: el.cellphone,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex align-items-center justify-content-between mt-2 mb-2">
                            <div className="flex align-items-center p-2 text-base">
                              <div className="font-bold sm:text-base text-2xl mr-4 md:mr-5 lg:mr-8">
                                <label htmlFor="">지급금액</label>
                              </div>
                              <div className="">
                                <InputText
                                  value={
                                    el?.paymentAmt
                                      ?.toString()
                                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                      ?.toString() || ''
                                  }
                                  onChange={(e) => {
                                    const updatedSelectedPartner =
                                      filteredSelectPartner.map((item) => {
                                        if (item.partnerId === el.partnerId) {
                                          return {
                                            ...item,
                                            paymentAmt:
                                              e.target.value.replaceAll(
                                                ',',
                                                ''
                                              ),
                                          };
                                        }
                                        return item;
                                      });
                                    setSelectedPartner(updatedSelectedPartner);
                                  }}
                                  placeholder="지급금액 입력"
                                />
                              </div>
                            </div>

                            {/* <Button.Default
                              label="수리사진 업로드 요청"
                              icon="pi pi-comment"
                              className="p-button-outlined"
                              onClick={() => {
                                setShowSmsModal(true);
                                setShowSmsType('PARTNER_UPLOAD_URL');
                                setSmsData({
                                  ...smsData,
                                  partnerInfo: el,
                                  destAddr: el.cellphone,
                                });
                              }}
                            /> */}
                          </div>
                        </>
                      )}
                      <div className="flex align-items-center justify-content-between">
                        <Calendar
                          value={el?.dueDate || null}
                          onChange={(e) => {
                            const updatedSelectedPartner =
                              filteredSelectPartner.map((item) => {
                                if (item.partnerId === el.partnerId) {
                                  return {
                                    ...item,
                                    dueDate: e.value,
                                  };
                                }
                                return item;
                              });
                            setSelectedPartner(updatedSelectedPartner);
                          }}
                          dateFormat="yy-mm-dd"
                          showButtonBar
                          showIcon
                          mask="9999-99-99"
                          placeholder="수리완료일자 선택"
                          id={el?.partenrId}
                          name="dueDate"
                          className="pl-2"
                        />
                        <div className="pr-2">
                          <ToggleButton
                            checked={
                              checked?.find((el) => el.status === 'Z08')?.value
                            }
                            onChange={(e) => onToggle('Z08', e.value)}
                            onLabel="완료"
                            offLabel="미확인"
                            onIcon="pi pi-flag-fill"
                            offIcon="pi pi-flag"
                          />
                        </div>
                      </div>
                      <div className="field grid align-items-center p-2 mb-0">
                        <div className="lg:col-3 md:col-6 col-12">
                          <DocsViewer
                            title="정비사진"
                            imgFiles={maintenanceImg}
                            buttonName={'P_MAINTENANCE' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_MAINTENANCE}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '정비사진',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12 ">
                          <DocsViewer
                            title="부품명세서(수리)"
                            imgFiles={completeImg}
                            buttonName={'P_COMPLETED' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_COMPLETED}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '부품명세서(수리)',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12 ">
                          <DocsViewer
                            title="보험금청구동의서"
                            imgFiles={insuranceImg}
                            buttonName={'P_INSURANCE' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_INSURANCE}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '보험금청구동의서',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12 ">
                          <DocsViewer
                            title="사업자등록증"
                            imgFiles={registerImg}
                            buttonName={'P_REGISTRATION' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            // deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_REGISTRATION}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '사업자등록증',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12 ">
                          <DocsViewer
                            title="사업자통장사본"
                            imgFiles={bankImg}
                            buttonName={'P_BANKBOOK' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            // deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_BANKBOOK}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '사업자통장사본',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                        <div className="lg:col-3 md:col-6 col-12 ">
                          <DocsViewer
                            title="기타"
                            imgFiles={etcImg}
                            buttonName={'P_ETC' + el.partnerId}
                            setCurTitle={setCurTitle}
                            setShowModal={setShowModal}
                            curImg={curImg}
                            setCurImg={setCurImg}
                            deleteFile={deleteFile}
                            handleClick={handleClick}
                            userType={partnerId ? true : false}
                          />
                          <input
                            ref={P_ETC}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e) =>
                              uploadFiles(
                                e.target.files,
                                '기타',
                                partnerUser && !!partnerId
                                  ? partnerId
                                  : el.partnerId
                              )
                            }
                          />
                        </div>
                      </div>
                      {(!dealerUser || !partnerUser) && aId ? null : (
                        <div className="col px-2">
                          <div className="flex justify-content-end align-items-center h-3rem mt-2">
                            <Button.Default
                              className="p-button-outlined"
                              icon="pi pi-download"
                              label="다운로드"
                              onClick={() => saveFilesAsZip(el, '수리')}
                              loading={downloadLoading}
                            />
                          </div>
                        </div>
                      )}
                      {/* <div className="px-2 mt-1">
                              <div className="sm:text-base text-2xl font-bold">
                                소견내용
                              </div>
                              <InputTextarea
                                placeholder={'소견내용 입력'}
                                // value={repairData?.repairOpinion || ''}
                                // onChange={(e) =>
                                //   setRepairData({
                                //     ...repairData,
                                //     repairOpinion: e.target.value,
                                //   })
                                // }
                                rows={2}
                                className=" w-full mt-2"
                              />
                            </div> */}
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <div>입고점을 선택해주세요.</div>
            )}
          </div>
        </BlockUI>
      </Panel>

      {showModal && curTitle && (
        <ImagesPopup
          title={curTitle}
          onHide={() => setShowModal(!showModal)}
          setFileItems={setFileItems}
          copyFile={copyFile}
          loading={loading}
          setLoading={setLoading}
          partnerId={curPartnerId}
          selectedPartner={selectedPartner}
        />
      )}
    </>
  );
};

export default PartnerDocument;

const ImagesPopup = ({
  onHide,
  title,
  partnerId,
  setFileItems,
  loading,
  setLoading,
  selectedPartner,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: 15 * 1024 * 1024,
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles, fileRejections) => {
      setLoading(true);
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };
      if (!!fileRejections.length) {
        console.log(fileRejections);
        setLoading(false);
        return window.cerp.toast.warn(
          '파일용량 15MB 이상 업로드 불가',
          `${fileRejections.length} 장`
        );
      }
      // console.log(acceptedFiles);
      acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );

      const compressedImages = await compressImages(acceptedFiles, options);

      let fileSize = 0;
      compressedImages.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      compressedImages.map((el) => {
        // fileSize.push(el.size);
        return (fileSize = Number(el.size) + fileSize);
      });

      // console.log('fileSize', fileSize);
      if (fileSize / 1024 / 1024 > 50) {
        return window.cerp.toast.warn('50MB 이상', '한번에 업로드 불가');
      }

      const code = DOC_TYPE.find((el) => el.label === title).value;

      const partCodeAttachedFiles = _.reduce(
        compressedImages,
        (res, v) => {
          res.push({
            documentCode: code,
            file: v,
            state: FileState.Inserted,
          });
          return res;
        },
        []
      );
      const { fileData, files } = CommonUtil.File.convertForServerClaimPartner(
        partCodeAttachedFiles
      );

      const partner = selectedPartner.find((el) => el.partnerId === partnerId);
      if (partner?.partnerDocuments) {
        partCodeAttachedFiles.map((el) => partner?.partnerDocuments.push(el));
      } else {
        setLoading(false);
        onHide();
        return window.cerp.toast.warn('저장을 먼저 해주세요.');
      }

      setFileItems((ps) => [...ps, ...files]);
      setLoading(false);
      onHide();
      window.cerp.toast.info(
        '업로드 성공',
        '저장 버튼 클릭하여 등록해 주세요.'
      );
    },
  });

  return (
    <>
      <Modal.Default
        header={`${title} 등록`}
        onHide={onHide}
        widthClass="30rem"
      >
        <BlockUI
          template={
            loading && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="flex flex-column">
            <div className="col">
              <div
                {...getRootProps({
                  className:
                    'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                  style: { wordBreak: 'keep-all' },
                })}
              >
                <input {...getInputProps()} />
                <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                  <i
                    className="pi pi-arrow-circle-down"
                    style={{ fontSize: '1.5rem' }}
                  />
                  사진을 끌어다 놓거나 클릭하여 등록해주세요.
                </div>
              </div>
            </div>
          </div>
        </BlockUI>
      </Modal.Default>
    </>
  );
};

const DOC_TYPE = [
  {
    value: 'P_CHECK',
    label: '점검사진',
  },
  {
    value: 'P_FRONT',
    label: '입고 차량전면사진',
  },
  {
    value: 'P_DASHBOARD',
    label: '입고 계기판사진',
  },
  {
    value: 'P_OPINION',
    label: '소견서',
  },
  {
    value: 'P_LIST',
    label: '견적서',
  },
  {
    value: 'P_PART',
    label: '부품명세서',
  },
  {
    value: 'P_MAINTENANCE',
    label: '정비사진',
  },
  {
    value: 'P_REGISTRATION',
    label: '사업자등록증',
  },
  {
    value: 'P_BANKBOOK',
    label: '사업자통장사본',
  },
  {
    value: 'P_INSURANCE',
    label: '보험금청구동의서',
  },
  {
    value: 'P_COMPLETED',
    label: '부품명세서(수리)',
  },
  {
    value: 'P_ETC',
    label: '기타',
  },
  {
    value: 'P_APPROVED',
    label: '승인견적서',
  },
];
