import React, { useEffect, useState } from 'react';
import * as Button from './../../components/Atoms/Button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { COUNT_PER_PAGE } from '../../constants/Constants.js';
import { BlockUI } from 'primereact/blockui';
import { Dialog } from 'primereact/dialog';
import _ from 'lodash';
import dayjs from 'dayjs';
import Settlement from '../../services/SettlementService.js';
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
const SafePaymentDetail = ({ onHide, date, companyId, day }) => {
  const [lazyParams, setLazyParams] = useState({ first: 0, rows: 30, page: 0 });
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getDayCompanyData = async (id, page) => {
    setIsLoading(true);
    const params = {
      settlementMonth: dayjs(date).format('YYYYMM'),
      customerCompanyId: id,
      page: page,
      size: 30,
    };
    try {
      const data = await Settlement.getMonthCustomerCompany(params);
      if (data) {
        setTableData(data.list);
        setTotalRecords(data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDayCustomerCompanyData = async (day, page) => {
    setIsLoading(true);
    const params = {
      settlementDay: day,
      page: page,
      size: 30,
    };
    try {
      const data = await Settlement.getDayCustomerCompany(params);
      if (data) {
        setTableData(data.list);
        setTotalRecords(data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!!companyId) {
      getDayCompanyData(companyId, lazyParams.page + 1);
    } else if (!!day) {
      getDayCustomerCompanyData(day, lazyParams.page + 1);
    }
  }, [companyId, lazyParams.page, day]);

  return (
    <BlockUI
      template={
        isLoading && (
          <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
        )
      }
      blocked={isLoading}
    >
      <Dialog
        modal
        visible={true}
        maximizable
        breakpoints={{ '960px': '90vw' }}
        header={`상세내역`}
        style={{ width: '90vw', height: '100vh' }}
        contentClassName="cm-dialog-content"
        onHide={onHide}
        footer={
          <div className="flex flex-auto align-items-center justify-content-center pt-4">
            <div className="flex flex-auto flex-wrap align-items-center justify-content-center gap-1">
              <Button.Default
                type="button"
                label="닫기"
                className="p-button-outlined"
                onClick={onHide}
              />
            </div>
          </div>
        }
      >
        <DataTable
          loading={isLoading}
          value={tableData}
          lazy
          rows={lazyParams.rows}
          first={lazyParams.first}
          totalRecords={totalRecords}
          paginator
          responsiveLayout="scroll"
          emptyMessage="데이터가 없습니다."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
          rowsPerPageOptions={COUNT_PER_PAGE}
          resizableColumns
          showGridlines
          onPage={(event) => {
            setLazyParams(event);
          }}
          dataKey="dataId"
        >
          <Column
            header="순번"
            align={'center'}
            body={(rowData, column) => <span>{column.rowIndex + 1}</span>}
          />
          {!!companyId && (
            <Column
              field="customerCompanyName"
              header="소속상사"
              body={({ customerCompanyName, customerComapnyId }) => (
                <span>{customerCompanyName}</span>
              )}
            />
          )}
          <Column
            align={'center'}
            header="성능번호"
            body={({ statementNumber }) => <span>{statementNumber}</span>}
          />
          <Column
            align={'center'}
            field="sellDate"
            header="매도일"
            body={({ sellDate }) => (
              <span>{dayjs(sellDate, 'YYYYMMDD').format('YYYY-MM-DD')}</span>
            )}
          />
          <Column
            align={'center'}
            header="성능일"
            body={({ checkDate }) => (
              <span>{dayjs(checkDate, 'YYYYMMDD').format('YYYY-MM-DD')}</span>
            )}
          />
          <Column
            align={'center'}
            field="licensePlateNumber"
            header="차량번호"
            body={({ licensePlateNumber }) => <span>{licensePlateNumber}</span>}
          />
          <Column
            align={'center'}
            field="producingCountry"
            header="국산/수입"
            body={({ producingCountry }) => (
              <div>{producingCountry === 'F' ? '수입' : '국산'}</div>
            )}
          />
          <Column
            align={'center'}
            field="vin"
            header="차대번호"
            body={({ vin }) => <div>{vin}</div>}
          />
          <Column
            align={'center'}
            field="cnm"
            header="차량명"
            body={({ cnm }) => <div>{cnm}</div>}
          />
          <Column
            align={'center'}
            header="주행거리"
            body={({ distanceDriven }) => <span>{distanceDriven}</span>}
          />
          <Column
            align={'center'}
            field="fuelName"
            header="연료"
            body={({ fuelName }) => <span>{fuelName}</span>}
          />
          <Column
            align={'center'}
            field="modelYear"
            header="연식"
            body={({ modelYear }) => <span>{modelYear}</span>}
          />
          <Column
            align={'center'}
            field="transmissionName"
            header="변속기"
            body={({ transmissionName }) => <span>{transmissionName}</span>}
          />
          <Column
            align={'center'}
            header="최초등록일"
            body={({ registrationDate }) => (
              <span>
                {dayjs(registrationDate, 'YYYYMMDD').format('YYYY-MM-DD')}
              </span>
            )}
          />
        </DataTable>
      </Dialog>
    </BlockUI>
  );
};

export default SafePaymentDetail;
