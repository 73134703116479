import axios from 'axios';
import _ from 'lodash';

const AxiosUtil = {
  Async: {
    async get(uri, queryString) {
      let result = { data: null, error: null };
      let url = uri;
      if (queryString) url += '?' + queryString;

      try {
        const {
          data: { data: resData = null },
        } = await axios.get(url);

        result.data = resData;
        // return { data: resData };
      } catch (e) {
        // result.error = e.response.data?.error;
        return Promise.reject(e.response?.data?.error);
      }

      return result;
    },
    async post(uri, data) {
      let result = { data: null, error: null };

      try {
        const {
          data: { data: resData },
        } = await axios.post(uri, data);

        result.data = resData;
      } catch (e) {
        // result.error = e.response.data?.error;
        return Promise.reject(e.response?.data?.error);
      }

      return result;
    },
    async put(uri, data) {
      let result = { data: null, error: null };

      try {
        const {
          data: { data: resData },
        } = await axios.put(uri, data);

        result.data = resData;
      } catch (e) {
        // result.error = e.response.data?.error;
        return Promise.reject(e.response?.data?.error);
      }

      return result;
    },
    async delete(uri, data) {
      let result = { data: null, error: null };

      try {
        const {
          data: { data: resData },
        } = await axios.delete(uri, data);

        result.data = resData;
      } catch (e) {
        // result.error = e.response.data?.error;
        return Promise.reject(e.response?.data?.error);
      }

      return result;
    },
    async multipart(uri, data, files = null, pfiles = null) {
      let result = { data: null, error: null };

      const formData = new FormData();

      if (data) formData.append('data', JSON.stringify(data));

      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append('files', file);
        });
      }

      if (pfiles && pfiles.length > 0) {
        pfiles.forEach((file) => {
          formData.append('pfiles', file);
        });
      }

      try {
        const {
          data: { data: resData },
        } = await axios({
          method: 'post',
          url: uri,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        result.data = resData;
      } catch (e) {
        return Promise.reject(e.response?.data?.error);
      }

      return result;
    },
    async updateMultipart(uri, data, files = null, pfiles = null) {
      let result = { data: null, error: null };

      const formData = new FormData();

      if (data) formData.append('data', JSON.stringify(data));

      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append('files', file);
        });
      }

      if (pfiles && pfiles.length > 0) {
        pfiles.forEach((file) => {
          formData.append('pfiles', file);
        });
      }

      try {
        const {
          data: { data: resData },
        } = await axios({
          method: 'put',
          url: uri,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        result.data = resData;
      } catch (e) {
        console.log(e, 'mulitpart error');
        return Promise.reject(e.response?.data?.error);
      }

      return result;
    },
  },
  exportToExcel: async (pathName, data) => {
    try {
      const res = await axios.get(`apis/excel/${pathName}`, {
        params: data,
        responseType: 'blob',
      });
      const contentDisposition = res.headers['content-disposition'];
      const [, fileNameData] = _.split(contentDisposition, ';');
      const [, fileName] = _.split(fileNameData, '=');

      const url = window.URL.createObjectURL(
        new Blob([res.data], {
          type: res.headers['content-type'],
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        decodeURIComponent(_.replace(fileName, /"/g, ''))
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.cerp.toast.clear();
      window.cerp.toast.success('엑셀 파일 다운로드에 성공했습니다.');
    } catch (error) {
      window.cerp.toast.clear();
      console.log(error);
      if (error?.code) {
        window.cerp.dialog.error(
          `[${error.code}] 엑셀 파일 다운로드 실패`,
          `${error.message}`
        );
      } else {
        window.cerp.toast.error('엑셀 다운로드에 실패했습니다.');
      }
    }
  },

  Sync: {
    get() {},
  },
};

export default AxiosUtil;
