import axios from 'axios';
import AxiosUtil from '../utils/axiosUtil';
import { BaseService } from './BaseService';

const BASE_URI = '/apis/shops';
export const ShopService = (function () {
  let instance;
  const baseService = BaseService.getInstance(BASE_URI, 'shop');

  function init() {
    return {
      ...baseService,
      checkAvailable: async function (code = '', data) {
        const { associationId, enterpriseId } = data;
        return await AxiosUtil.Async.get(
          BASE_URI + `/check/available-code`,
          `aid=${associationId}&eid=${enterpriseId}&sc=${code}`
        );
      },
      getAvailableStatementNumberAffixes: async function (
        associationId,
        selectedPrefix = ''
      ) {
        if (associationId > 0) {
          return await AxiosUtil.Async.get(
            BASE_URI + `/check/available-affixes`,
            `aid=${associationId}&spf=${selectedPrefix}`
          );
        }
      },
      register: async function (data) {
        return await AxiosUtil.Async.post(BASE_URI, data);
      },
      update: async function (data) {
        return await AxiosUtil.Async.put(BASE_URI, data);
      },
      list: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return AxiosUtil.Async.get(BASE_URI, queryString);
      },
      getData: async function (shopId) {
        return await AxiosUtil.Async.get(BASE_URI + `/${shopId}`);
      },
      delete: async function (shopId) {
        return await AxiosUtil.Async.delete(BASE_URI + `/${shopId}`);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();

export const Shops = {
  getData: async (shopId) => {
    const res = await axios.get(BASE_URI + `/${shopId}`);
    return res.data.data;
  },
};
