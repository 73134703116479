import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { ColumnGroup } from 'primereact/columngroup';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import { Tag } from 'primereact/tag';
import { Row } from 'primereact/row';
import { Tooltip } from 'primereact/tooltip';
import * as Button from './../../components/Atoms/Button';
import _ from 'lodash';
import dayjs from 'dayjs';
import { COUNT_PER_PAGE, YN } from '../../constants/Constants';
import { Checkbox } from 'primereact/checkbox';
import { PC_BASE_BLUEPRINT } from '../../constants/PerformanceCheckConstants';
import { classNames } from 'primereact/utils';
import { CodeUtil } from '../../utils/CodeUtil';
import { CommonUtil } from '../../utils/commonUtil';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import PaymentDialog from '../../components/Dialog/PaymentDialog/PaymentDialog';
import PaymentUpdateDialog from '../../components/Dialog/PaymentDialog/PaymentUpdateDialog';
import RangeCalendar from '../../components/Common/RangeCalendar';
import SearchMenu from '../../components/Menu/SearchMenu';
import { ServiceProvider } from '../../services';
import { myInfoSelector } from '../../recoil/selectors';
import { touchUIState } from '../../recoil/atoms';
import { useAuthorizedFetch } from '../../hooks/useAuthorizedFetch';
import { Claim } from '../../services/ClaimService';
import SendSMS from '../Accident/SendSMS.jsx';
import { Shops } from '../../services/ShopService.js';

const today = dayjs();
const startDate = today;
const endDate = today;

const priceFormatter = new Intl.NumberFormat('ko-KR', { style: 'decimal' });

const DEFAULT_SEARCH_CONDITIONS = {
  associations: [],
  enterprises: [],
  shops: [],
  startDate: startDate.toDate(),
  endDate: endDate.toDate(),
  statementNumber: '',
  carSearchKeyword: '',
  customerSearchBy: '1',
  customerSearchKeyword: '',
  payYN: '',
};

const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
};

const performanceCheckPaymentService = ServiceProvider.performanceCheckPayment;

const PaymentList = () => {
  const touchUI = useRecoilValue(touchUIState);
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
    myShopMainPhone,
  } = useMemo(
    () => MyInfoUtil.unpack(myInfoLoadable.contents),
    [myInfoLoadable.contents]
  );

  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });

  const [showDetail, setShowDetail] = useState(false);
  const [codes, setCodes] = useState({});
  const [exporting, setExporting] = useState(false);
  const {
    loading,
    totalRecords,
    data,
    summary,
    getList,
    getSummary,
    getData,
    initData,
  } = useAuthorizedFetch(
    performanceCheckPaymentService,
    searchConditions,
    setSearchConditions,
    DEFAULT_SEARCH_CONDITIONS,
    lazyParams.page,
    lazyParams.rows,
    true
  );

  const [currentPaymentInfo, setCurrentPaymentInfo] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [showPaymentUpdate, setShowPaymentUpdate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  async function exportToExcel() {
    setExporting(true);

    const params = {
      ...searchConditions,
      page: lazyParams.page + 1,
      size: lazyParams.rows,
    };
    params.startDate = dayjs(params.startDate).format('YYYYMMDD');
    params.endDate = dayjs(params.endDate).format('YYYYMMDD');

    await performanceCheckPaymentService.exportExcel(params);

    setExporting(false);
  }

  function handleSelectAll(
    selected,
    field,
    updateCodes,
    updateSearchConditions
  ) {
    const update = [];

    if (selected && selected.includes('')) {
      update.push(
        ..._.get(codes, `${field}`).map((option) => {
          if (option.value !== '') {
            option.disabled = true;
          }
          return option;
        })
      );

      setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));
      setSearchConditions((ps) => ({
        ...ps,
        [field]: [_.get(codes[field], '0.value')],
        ...updateSearchConditions,
      }));
      return;
    }

    update.push(
      ..._.get(codes, `${field}`).map((option) => {
        if (option.disabled) delete option.disabled;
        return option;
      })
    );

    setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));

    if (selected === null) {
      setSearchConditions((ps) => ({ ...ps, [field]: '' }));
      return;
    }

    setSearchConditions((ps) => ({
      ...ps,
      [field]: selected,
      ...updateSearchConditions,
    }));
  }

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);

        const [insurances, insuranceId, insurance] =
          await CodeUtil.getInsuranceCodes({});
        const [associations, associationId, association] =
          await CodeUtil.getAssociationCodes(myInfo, {
            preSelectedAssociationId: myAssociationId,
          });
        const [enterprises, enterpriseId, enterprise] =
          await CodeUtil.getEnterpriseCodes(myInfo, {
            isParentEmpty: _.isEmpty(associations),
            associationId: myAssociationId,
          });
        const [shops, shopId, shop] = await CodeUtil.getShopCodes(myInfo, {
          isParentEmpty: _.isEmpty(enterprises),
          enterpriseId: myEnterpriseId,
        });

        setCodes((ps) => ({
          ...ps,
          insurances,
          associations,
          enterprises,
          shops,
        }));

        await getList({
          ...searchConditions,
          associations: [associationId],
          enterprises: [enterpriseId],
          shops: [shopId],
        });
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.state]);

  const countRefund = data?.filter(
    (el) => el.insurancePriceRefundedAt !== null
  )?.length;

  const [payCategory, setPayCategory] = useState({
    checkPrice: true,
    insurancePrice: true,
  });

  const handleCheckboxChange = (value) => {
    setPayCategory({ ...payCategory, [value]: !payCategory[value] });
  };
  const [customerInfo, setCustomerInfo] = useState({
    shopName: '',
    bankName: '',
    bankAccount: '',
    bankAccountName: '',
  });

  const [showSmsMoal, setShowSmsModal] = useState(false);
  const [smsLoading, setSmsLoading] = useState(false);
  const [smsData, setSmsData] = useState({
    msgType: '',
    sourceAddr: '',
    destAddr: '',
    subject: null, //opt,
    contents: '',
    fileData: null, //opt
  });

  useEffect(() => {
    if (!!myShopMainPhone) {
      setSmsData({ ...smsData, sourceAddr: myShopMainPhone });
    }
  }, [myShopMainPhone]);

  //ANCHOR - post sms
  const postSms = async () => {
    setSmsLoading(true);
    const type = 'lms';

    const postData = {
      msgType: type,
      sourceAddr: smsData.sourceAddr,
      destAddr: smsData.destAddr.replaceAll('-', ''),
      subject: null, //opt,
      contents: smsData.contents,
      msgSubType: null,
      fileData: null, //opt
      url: smsData.url ?? null, // opt
      msgAuthType: 'CARMON',
    };
    // return console.log(postData);
    try {
      const data = await Claim.postSMS(postData);

      if (data) {
        window.cerp.toast.info('메세지 전송이 완료되었습니다.');
        setShowSmsModal(false);
      }
    } catch (error) {
      setShowSmsModal(false);
      console.log(error);
      window.cerp.toast.warn(error?.response?.data.error?.message);
    }
    setSmsLoading(false);
  };

  const getShopData = async (id) => {
    try {
      const data = await Shops.getData(id);
      if (data) {
        setCustomerInfo({
          shopName: data.shopName,
          bankName: data.bankName,
          bankAccount: data.bankAccount,
          bankAccountName: data.bankAccountName,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!!myShopId) {
      getShopData(myShopId);
    }
  }, [myShopId]);

  const sendSms = () => {
    const list = [];
    selectedRows.map((el) =>
      list.push({
        customerCompanyName: el.customerCompanyName,
        customerName: el.customerName,
        customerContact: el.customerContact,
        checkDate: el.checkDate,
        licensePlateNumber: el.licensePlateNumber,
        carName: el.carName,
        checkPriceTotal: payCategory.checkPrice
          ? el.checkPrice + el.checkPriceVat
          : 0,
        insurancePriceTotal: payCategory.insurancePrice
          ? el.insurancePrice + el.insurancePriceVat
          : 0,
      })
    );
    setCustomerInfo({ ...customerInfo, paymentInfo: list });
    setShowSmsModal(true);
  };

  const [rowClick, setRowClick] = useState(true);
  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
              return (
                <>
                  <SearchMenu
                    onInit={() => initData()}
                    onSearch={() =>
                      getData(
                        searchConditions,
                        _.get(DEFAULT_LAZY_PARAMS, 'page'),
                        _.get(DEFAULT_LAZY_PARAMS, 'rows')
                      )
                    }
                  >
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속협회</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.associations}
                        display="chip"
                        placeholder="전체 협회"
                        value={searchConditions.associations}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [enterprises, enterpriseId, enterprise] =
                            await CodeUtil.getEnterpriseCodes(myInfo, {
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'associations')
                              ),
                              associationId: value,
                            });
                          const [shops, shopId, shop] =
                            await CodeUtil.getShopCodes(myInfo, {
                              isParentEmpty: _.isEmpty(enterprises),
                            });
                          handleSelectAll(
                            value,
                            'associations',
                            { enterprises, shops },
                            { enterprises: [], shops: [] }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속점검법인</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.enterprises}
                        display="chip"
                        placeholder="전체 업체"
                        value={searchConditions.enterprises}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [shops, shopId, shop] =
                            await CodeUtil.getShopCodes(myInfo, {
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'enterprises')
                              ),
                              enterpriseId: value,
                            });
                          handleSelectAll(
                            value,
                            'enterprises',
                            { shops },
                            { shops: [] }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속점검장</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.shops}
                        display="chip"
                        placeholder="전체 점검장"
                        value={searchConditions.shops}
                        filter
                        showSelectAll={false}
                        onChange={({ value }) => {
                          handleSelectAll(value, 'shops');
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">성능점검일자</label>
                      <RangeCalendar
                        startDate={searchConditions.startDate}
                        endDate={searchConditions.endDate}
                        onStartDateChanged={(date) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            startDate: date,
                          }));
                        }}
                        onEndDateChanged={(date) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            endDate: date,
                          }));
                        }}
                        touchUI={touchUI}
                        showNavigators={true}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="statementNumber">성능지 번호</label>
                      <InputText
                        id="statementNumber"
                        className="w-full"
                        value={searchConditions.statementNumber}
                        onChange={({ target: { value } }) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            statementNumber: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">차량</label>
                      <InputText
                        className="w-full"
                        value={searchConditions.carSearchKeyword}
                        placeholder="차량번호, 모델, 차대번호로 검색"
                        onChange={({ target: { value } }) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            carSearchKeyword: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">고객</label>
                      <InputText
                        className="w-full"
                        value={searchConditions.customerSearchKeyword}
                        placeholder={
                          searchConditions.customerSearchBy === '1'
                            ? '고객 이름, 연락처로 검색'
                            : '매매상사 이름, 대표전화, 사업자등록번호로 검색'
                        }
                        onChange={({ target: { value } }) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            customerSearchKeyword: value,
                          }));
                        }}
                      />
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_dealerName"
                            name="customerSearchBy"
                            value="1"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                customerSearchBy: value,
                              }));
                            }}
                            checked={searchConditions.customerSearchBy === '1'}
                          />
                          <label htmlFor="radio_dealerName">고객</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_companyName"
                            name="customerSearchBy"
                            value="2"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                customerSearchBy: value,
                              }));
                            }}
                            checked={searchConditions.customerSearchBy === '2'}
                          />
                          <label htmlFor="radio_companyName">매매상사</label>
                        </div>
                      </div>
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">수납여부</label>
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_payYn_a"
                            name="payYN"
                            value=""
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                payYN: value,
                              }));
                            }}
                            checked={searchConditions.payYN === ''}
                          />
                          <label htmlFor="radio_payYn_a">전체</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_payYn_y"
                            name="payYN"
                            value="Y"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                payYN: value,
                              }));
                            }}
                            checked={searchConditions.payYN === 'Y'}
                          />
                          <label htmlFor="radio_payYn_y">수납</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_payYn_n"
                            name="payYN"
                            value="N"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                payYN: value,
                              }));
                            }}
                            checked={searchConditions.payYN === 'N'}
                          />
                          <label htmlFor="radio_payYn_n">미납</label>
                        </div>
                      </div>
                    </div>
                  </SearchMenu>

                  <div className="card flex flex-auto flex-wrap align-items-center justify-content-between">
                    <div className="flex align-items-center">
                      <div className="mr-3">
                        <Checkbox
                          className="mr-2"
                          inputId="checkPrice"
                          value="checkPrice"
                          onChange={(e) => handleCheckboxChange(e.value)}
                          checked={payCategory.checkPrice}
                        />
                        <label
                          htmlFor="checkPrice"
                          className="p-checkbox-label"
                        >
                          점검료
                        </label>
                      </div>
                      <div className="mr-3">
                        <Checkbox
                          className="mr-2"
                          inputId="insurancePrice"
                          value="insurancePrice"
                          onChange={(e) => handleCheckboxChange(e.value)}
                          checked={payCategory.insurancePrice}
                        />
                        <label
                          htmlFor="insurancePrice"
                          className="p-checkbox-label"
                        >
                          보험료
                        </label>
                      </div>

                      <Button.Default
                        label="문자전송"
                        className="p-button-outlined"
                        icon="pi pi-envelope"
                        disabled={
                          !selectedRows.length ||
                          (!payCategory.checkPrice &&
                            !payCategory.insurancePrice) ||
                          !myShopMainPhone
                        }
                        loading={smsLoading}
                        onClick={() => sendSms()}
                      />
                      <Tooltip target=".custom-target-icon" />
                      {!myShopMainPhone ? (
                        <i
                          className="custom-target-icon pi pi-question-circle p-text-secondary ml-2"
                          data-pr-tooltip="대표번호 등록이 필요합니다."
                          data-pr-position="right"
                        />
                      ) : null}
                    </div>

                    <div className="flex align-items-center">
                      <div className="mr-3">
                        <Checkbox
                          className="mr-2"
                          inputId="chk_showDetail"
                          onChange={({ checked }) => setShowDetail(checked)}
                          checked={showDetail}
                        />
                        <label
                          htmlFor="chk_showDetail"
                          className="p-checkbox-label"
                        >
                          상세보기
                        </label>
                      </div>
                      <Button.Default
                        label="엑셀 다운로드"
                        className="p-button-outlined"
                        icon="pi pi-download"
                        disabled={exporting}
                        loading={exporting}
                        onClick={async () => await exportToExcel()}
                      />
                    </div>
                  </div>

                  <div className="card">
                    <DataTable
                      loading={loading}
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={totalRecords}
                      paginator
                      responsiveLayout="scroll"
                      emptyMessage="데이터가 없습니다."
                      footerColumnGroup={
                        <ColumnGroup>
                          <Row>
                            <Column
                              colSpan={8}
                              footerClassName="text-right"
                              footer="현재 리스트 합계"
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => (
                                <div>
                                  {showDetail ? (
                                    <>
                                      <div className="flex justify-content-between">
                                        <span className="mr-3">점검료</span>
                                        <span>
                                          {priceFormatter.format(
                                            _.sumBy(data, 'checkPrice')
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex justify-content-between">
                                        <span className="mr-3">VAT</span>
                                        <span>
                                          +{' '}
                                          {priceFormatter.format(
                                            _.sumBy(data, 'checkPriceVat')
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex justify-content-between">
                                        <span className="mr-3">할인</span>
                                        <span>
                                          -{' '}
                                          {priceFormatter.format(
                                            _.sumBy(
                                              data,
                                              'discountedCheckPrice'
                                            )
                                          )}
                                        </span>
                                      </div>
                                      <hr className="p-0 m-0" />
                                      <div className="flex justify-content-between">
                                        <span className="mr-3">합계</span>
                                        <strong>
                                          {priceFormatter.format(
                                            _.sumBy(data, 'checkPrice') +
                                              _.sumBy(data, 'checkPriceVat') -
                                              _.sumBy(
                                                data,
                                                'discountedCheckPrice'
                                              )
                                          )}
                                        </strong>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <span>
                                        {priceFormatter.format(
                                          _.sumBy(data, 'checkPrice') +
                                            _.sumBy(data, 'checkPriceVat') -
                                            _.sumBy(
                                              data,
                                              'discountedCheckPrice'
                                            )
                                        )}
                                      </span>
                                    </>
                                  )}
                                </div>
                              )}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => {
                                const countsCheckPrice = _.countBy(
                                  data,
                                  (item) =>
                                    _.get(item, 'checkPricePaidYn') === 'Y'
                                );
                                return (
                                  <div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>결제완료 : </span>
                                      <span>{countsCheckPrice[true] ?? 0}</span>
                                    </div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>결제대기 : </span>
                                      <span>
                                        {countsCheckPrice[false] ?? 0}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => {
                                const counts = _.countBy(
                                  data,
                                  (item) => _.get(item, 'assuranceType') === '2'
                                );
                                return (
                                  <div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>보험사보증 : </span>
                                      <span>{counts[true] ?? 0}</span>
                                    </div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>자가보증 : </span>
                                      <span>{counts[false] ?? 0}</span>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => (
                                <div>
                                  {showDetail ? (
                                    <>
                                      <div className="flex justify-content-between">
                                        <span className="mr-3">보험료</span>
                                        <span>
                                          {priceFormatter.format(
                                            _.sumBy(data, 'insurancePrice')
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex justify-content-between">
                                        <span className="mr-3">VAT</span>
                                        <span>
                                          +{' '}
                                          {priceFormatter.format(
                                            _.sumBy(data, 'insurancePriceVat')
                                          )}
                                        </span>
                                      </div>
                                      <br />
                                      <hr className="p-0 m-0" />
                                      <div className="flex justify-content-between">
                                        <span className="mr-3">합계</span>
                                        <strong>
                                          {priceFormatter.format(
                                            _.sumBy(data, 'insurancePrice') +
                                              _.sumBy(data, 'insurancePriceVat')
                                          )}
                                        </strong>
                                      </div>
                                    </>
                                  ) : (
                                    <span>
                                      {priceFormatter.format(
                                        _.sumBy(data, 'insurancePrice') +
                                          _.sumBy(data, 'insurancePriceVat')
                                      )}
                                    </span>
                                  )}
                                </div>
                              )}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => {
                                const counts = _.countBy(
                                  data,
                                  (item) =>
                                    item.insurancePricePaidYn === 'Y' &&
                                    item.insurancePriceRefundedAt === null
                                );
                                const countRefunds = _.countBy(
                                  data,
                                  (item) =>
                                    item.insurancePriceRefundedAt !== null
                                );

                                return (
                                  <div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>결제완료 : </span>
                                      <span>{counts[true] ?? 0}</span>
                                    </div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>결제대기 : </span>
                                      {(counts[false] ?? 0) -
                                        (countRefunds[true] ?? 0)}
                                    </div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>반환완료 : </span>
                                      <span>{countRefund}</span>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => (
                                <div>
                                  {showDetail ? (
                                    <>
                                      <div className="flex justify-content-between">
                                        <span className="mr-3">
                                          점검료+보험료
                                        </span>
                                        <span>
                                          {priceFormatter.format(
                                            _.sumBy(data, 'checkPrice') +
                                              _.sumBy(data, 'insurancePrice')
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex justify-content-between">
                                        <span className="mr-3">VAT</span>
                                        <span>
                                          +{' '}
                                          {priceFormatter.format(
                                            _.sumBy(data, 'checkPriceVat') +
                                              _.sumBy(data, 'insurancePriceVat')
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex justify-content-between">
                                        <span className="mr-3">할인</span>
                                        <span>
                                          -{' '}
                                          {priceFormatter.format(
                                            _.sumBy(
                                              data,
                                              'discountedCheckPrice'
                                            )
                                          )}
                                        </span>
                                      </div>
                                      {/* <br /> */}
                                      <hr className="p-0 m-0" />
                                      <div className="flex justify-content-between">
                                        <span className="mr-3">합계</span>
                                        <strong>
                                          {priceFormatter.format(
                                            _.sumBy(data, 'checkPrice') +
                                              _.sumBy(data, 'insurancePrice') +
                                              _.sumBy(data, 'checkPriceVat') +
                                              _.sumBy(
                                                data,
                                                'insurancePriceVat'
                                              ) -
                                              _.sumBy(
                                                data,
                                                'discountedCheckPrice'
                                              )
                                          )}
                                        </strong>
                                      </div>
                                    </>
                                  ) : (
                                    <span>
                                      {priceFormatter.format(
                                        _.sumBy(data, 'checkPrice') +
                                          _.sumBy(data, 'insurancePrice') +
                                          _.sumBy(data, 'checkPriceVat') +
                                          _.sumBy(data, 'insurancePriceVat') -
                                          _.sumBy(data, 'discountedCheckPrice')
                                      )}
                                    </span>
                                  )}
                                </div>
                              )}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => {
                                const checkPaid = data.filter(
                                  (el) => el.checkPricePaidYn === 'Y'
                                );
                                const insurPaid = data.filter(
                                  (el) => el.insurancePricePaidYn === 'Y'
                                );

                                return (
                                  <span>
                                    {!!checkPaid.length || !!insurPaid.length
                                      ? priceFormatter.format(
                                          _.sumBy(checkPaid, 'checkPrice') +
                                            _.sumBy(
                                              checkPaid,
                                              'checkPriceVat'
                                            ) +
                                            _.sumBy(
                                              insurPaid,
                                              'insurancePrice'
                                            ) +
                                            _.sumBy(
                                              insurPaid,
                                              'insurancePriceVat'
                                            ) -
                                            _.sumBy(
                                              checkPaid,
                                              'discountedCheckPrice'
                                            )
                                        )
                                      : 0}
                                  </span>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => {
                                const checkPaid = data.filter(
                                  (el) => el.checkPricePaidYn === 'N'
                                );
                                const insurPaid = data.filter(
                                  (el) => el.insurancePricePaidYn === 'N'
                                );
                                const refund = data.filter(
                                  (el) => el.insurancePriceRefundedAt !== null
                                );

                                return (
                                  <span>
                                    {!!checkPaid.length || !!insurPaid.length
                                      ? priceFormatter.format(
                                          _.sumBy(checkPaid, 'checkPrice') +
                                            _.sumBy(
                                              checkPaid,
                                              'checkPriceVat'
                                            ) +
                                            _.sumBy(
                                              insurPaid,
                                              'insurancePrice'
                                            ) +
                                            _.sumBy(
                                              insurPaid,
                                              'insurancePriceVat'
                                            ) -
                                            _.sumBy(
                                              checkPaid,
                                              'discountedCheckPrice'
                                            ) -
                                            _.sumBy(refund, 'insurancePrice') -
                                            _.sumBy(refund, 'insurancePriceVat')
                                        )
                                      : 0}
                                  </span>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => {
                                const counts = _.countBy(
                                  data,
                                  (item) => _.get(item, 'isRecheck') === 'Y'
                                );
                                return (
                                  <div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>신규성능 : </span>
                                      <span>{counts[false] ?? 0}</span>
                                    </div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>재성능 : </span>
                                      <span>{counts[true] ?? 0}</span>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                            <Column colSpan={5} />
                          </Row>
                          <Row>
                            <Column
                              colSpan={8}
                              footerClassName="text-right bg-yellow-200"
                              footer="전체 합계"
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() =>
                                priceFormatter.format(
                                  _.get(summary, 'sumTotalCheckPrice')
                                )
                              }
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() => {
                                return (
                                  <div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>결제완료 : </span>
                                      <span>
                                        {_.get(
                                          summary,
                                          'countCheckPricePaidY'
                                        ) || 0}
                                      </span>
                                    </div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>결제대기 : </span>
                                      <span>
                                        {_.get(
                                          summary,
                                          'countCheckPricePaidN'
                                        ) || 0}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() => {
                                return (
                                  <div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>보험사보증 : </span>
                                      <span>
                                        {_.get(
                                          summary,
                                          'countInsuranceCompany'
                                        ) || 0}
                                      </span>
                                    </div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>자가보증 : </span>
                                      <span>
                                        {_.get(summary, 'countInsuranceSelf') ||
                                          0}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() => {
                                return (
                                  <div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>총 합계 금액 : </span>
                                      <span>
                                        {priceFormatter.format(
                                          _.get(
                                            summary,
                                            'sumTotalInsurancePrice'
                                          )
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>총 반환 금액 : </span>
                                      <span>
                                        {priceFormatter.format(
                                          _.get(
                                            summary,
                                            'sumRefundedInsurancePrice'
                                          ) || 0
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() => {
                                return (
                                  <div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>결제완료 : </span>
                                      <span>
                                        {_.get(
                                          summary,
                                          'countInsurancePricePaidY'
                                        )}
                                      </span>
                                    </div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>결제대기 : </span>
                                      <span>
                                        {_.get(
                                          summary,
                                          'countInsurancePricePaidN'
                                        ) || 0}
                                      </span>
                                    </div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>반환완료 : </span>
                                      <span>
                                        {_.get(
                                          summary,
                                          'countRefundedInsurancePrice'
                                        ) || 0}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() =>
                                priceFormatter.format(
                                  _.get(summary, 'sumTotalPrice')
                                )
                              }
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() =>
                                priceFormatter.format(
                                  _.get(summary, 'sumPaidPrice')
                                )
                              }
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() =>
                                priceFormatter.format(
                                  _.get(summary, 'sumUnpaidPrice')
                                )
                              }
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() => {
                                return (
                                  <div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>신규성능 : </span>
                                      <span>
                                        {_.get(summary, 'countNewcheck') || 0}
                                      </span>
                                    </div>
                                    <div className="flex justify-content-between align-items-center">
                                      <span>재성능 : </span>
                                      <span>
                                        {_.get(summary, 'countRecheck') || 0}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              colSpan={5}
                            />
                            {/* <Column footerClassName="text-right bg-yellow-200" />
                            <Column footerClassName="text-right bg-yellow-200" /> */}
                          </Row>
                        </ColumnGroup>
                      }
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      onPage={async (event) => {
                        setLazyParams(event);
                        await getList(searchConditions, event.page, event.rows);
                      }}
                      dataKey="dataId"
                      selectionMode={rowClick ? null : 'checkbox'}
                      selection={selectedRows}
                      onSelectionChange={(e) => setSelectedRows(e.value)}
                    >
                      <Column selectionMode="multiple" />
                      <Column
                        header="순번"
                        align={'center'}
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        header="수납관리"
                        body={({ dataId }) => {
                          return (
                            <Button.Default
                              className="p-button-outlined"
                              label="수납 관리"
                              icon="pi pi-wallet"
                              onClick={() => {
                                const findItem = data.find(
                                  (el) => el.dataId === dataId
                                );
                                setShowPayment(true);
                                setCurrentPaymentInfo(findItem);
                              }}
                            />
                          );
                        }}
                      />
                      <Column
                        header="점검료수정"
                        body={({ dataId, checkPricePaidYn, checkPrice }) => {
                          return (
                            <Button.Default
                              className="p-button-outlined p-button-secondary"
                              label="수정"
                              // disabled={checkPrice === 0}
                              icon="pi pi-pencil"
                              onClick={() => {
                                const findItem = data.find(
                                  (el) => el.dataId === dataId
                                );
                                setShowPaymentUpdate(true);
                                setCurrentPaymentInfo(findItem);
                              }}
                            />
                          );
                        }}
                      />
                      <Column
                        field="statementNumber"
                        header={
                          <div>
                            성능점검일자
                            <br />
                            성능지 번호
                          </div>
                        }
                        body={({ statementNumber, checkDate }) => (
                          <div>
                            {dayjs(checkDate).format('YYYY-MM-DD')}
                            <br />
                            {statementNumber}
                          </div>
                        )}
                      />
                      <Column
                        field="carName"
                        header={
                          <div>
                            차량명
                            <br />
                            차량번호
                          </div>
                        }
                        body={({ carName, licensePlateNumber }) => (
                          <div>
                            {carName}
                            <br />
                            {licensePlateNumber}
                          </div>
                        )}
                      />
                      <Column
                        field="customerCompanyName"
                        header="소속상사"
                        body={({
                          customerCompanyName,
                          customerCompanyContact,
                        }) => (
                          <span>
                            {customerCompanyName}
                            <br />(
                            {customerCompanyContact &&
                              CommonUtil.Formatter.phone(
                                customerCompanyContact
                              )}
                            )
                          </span>
                        )}
                      />
                      <Column
                        field="customerName"
                        header="고객명"
                        body={({ customerName, customerContact }) => (
                          <span>
                            {customerName}
                            <br />(
                            {customerContact &&
                              CommonUtil.Formatter.cellphone(customerContact)}
                            )
                          </span>
                        )}
                      />
                      <Column
                        field="checkPrice"
                        // className="text-right"
                        header={
                          <div>
                            {showDetail ? (
                              <>
                                <span>점검료</span>
                                <br />
                                <span>VAT</span>
                                <br />
                                <span>할인</span>
                                <hr className="p-0 m-0" />
                                <span>총 합계 금액</span>
                              </>
                            ) : (
                              <>
                                <span>성능 점검료</span>
                                <br />
                                <span>(VAT 포함)</span>
                              </>
                            )}
                          </div>
                        }
                        body={({
                          checkPrice,
                          checkPriceVat,
                          discountedCheckPrice,
                        }) => (
                          <div>
                            {showDetail ? (
                              <>
                                <div className="flex justify-content-between">
                                  <span className="mr-3">점검료</span>
                                  <span>
                                    {priceFormatter.format(checkPrice)}
                                  </span>
                                </div>
                                {/* <br /> */}
                                <div className="flex justify-content-between">
                                  <span className="mr-3">VAT</span>
                                  <span>
                                    + {priceFormatter.format(checkPriceVat)}
                                  </span>
                                </div>
                                {/* <br /> */}
                                <div className="flex justify-content-between">
                                  <span className="mr-3">할인</span>
                                  <span>
                                    -{' '}
                                    {priceFormatter.format(
                                      discountedCheckPrice
                                    )}
                                  </span>
                                </div>
                                <hr className="p-0 m-0" />
                                <div className="flex justify-content-between">
                                  <span className="mr-3">합계</span>
                                  <strong>
                                    {priceFormatter.format(
                                      checkPrice +
                                        checkPriceVat -
                                        discountedCheckPrice
                                    )}
                                  </strong>
                                </div>
                              </>
                            ) : (
                              <div className="text-right">
                                <span>
                                  {priceFormatter.format(
                                    checkPrice +
                                      checkPriceVat -
                                      discountedCheckPrice
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      />
                      <Column
                        field="checkPricePaidYn"
                        header="점검료 결제여부"
                        className="text-center"
                        body={({ checkPricePaidYn }) => (
                          <Tag
                            value={
                              checkPricePaidYn === YN.YES
                                ? '결제완료'
                                : '결제대기'
                            }
                            className={classNames('text-sm', {
                              'bg-green-400': checkPricePaidYn === YN.YES,
                              'bg-orange-400': checkPricePaidYn !== YN.YES,
                            })}
                          />
                        )}
                      />
                      <Column
                        field="assuranceType"
                        header={
                          <div>
                            보증구분
                            <br />
                            보험사
                          </div>
                        }
                        body={({ assuranceType, insuranceCompany }) => (
                          <div>
                            <span>
                              {_.get(
                                _.find(
                                  PC_BASE_BLUEPRINT.ASSRNC_TY_SE_CODE
                                    .valueItems,
                                  { value: assuranceType }
                                ),
                                'label'
                              )}
                            </span>
                            <br />
                            <span>
                              {_.get(
                                _.find(codes.insurances, {
                                  value: insuranceCompany,
                                }),
                                'label'
                              )}
                            </span>
                          </div>
                        )}
                      />
                      <Column
                        field="insurancePrice"
                        header={
                          <div>
                            {showDetail ? (
                              <>
                                <span>보험료</span>
                                <br />
                                <span>VAT</span>
                                <br />
                                <br />
                                <hr className="p-0 m-0" />
                                <span>총 합계 금액</span>
                              </>
                            ) : (
                              <>
                                <span>성능 보험료</span>
                                <br />
                                <span>(VAT 포함)</span>
                              </>
                            )}
                          </div>
                        }
                        body={({ insurancePrice, insurancePriceVat }) => (
                          <div>
                            {showDetail ? (
                              <>
                                <div className="flex justify-content-between">
                                  <span className="mr-3">보험료</span>
                                  <span>
                                    {priceFormatter.format(insurancePrice)}
                                  </span>
                                </div>
                                <div className="flex justify-content-between">
                                  <span className="mr-3">VAT</span>
                                  <span>
                                    + {priceFormatter.format(insurancePriceVat)}
                                  </span>
                                </div>
                                <br />
                                <hr className="p-0 m-0" />
                                <div className="flex justify-content-between">
                                  <span className="mr-3">합계</span>
                                  <strong>
                                    {priceFormatter.format(
                                      insurancePrice + insurancePriceVat
                                    )}
                                  </strong>
                                </div>
                              </>
                            ) : (
                              <div className="text-right">
                                <span>
                                  {priceFormatter.format(
                                    insurancePrice + insurancePriceVat
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      />
                      <Column
                        field="insurancePricePaidYn"
                        header="보험료 결제여부"
                        className="text-center"
                        body={({
                          insurancePricePaidYn,
                          insurancePriceRefundedAt,
                          assuranceType,
                        }) => (
                          <Tag
                            value={
                              insurancePriceRefundedAt !== null
                                ? '반환완료'
                                : insurancePricePaidYn === YN.YES ||
                                  assuranceType === '1'
                                ? '결제완료'
                                : '결제대기'
                            }
                            className={classNames('text-sm', {
                              'bg-red-400': insurancePriceRefundedAt !== null,
                              'bg-green-400':
                                insurancePricePaidYn === YN.YES ||
                                (assuranceType === '1' &&
                                  insurancePricePaidYn !== YN.YES),
                              'bg-orange-400':
                                insurancePricePaidYn !== YN.YES &&
                                assuranceType === '2',
                            })}
                          />
                        )}
                      />
                      <Column
                        field="summaryPrice"
                        header={
                          <div>
                            {showDetail ? (
                              <>
                                <span>점검료 + 보험료</span>
                                <br />
                                <span>VAT</span>
                                <br />
                                <span>할인 금액</span>
                                <hr className="p-0 m-0" />
                                <span>총 합계 금액</span>
                              </>
                            ) : (
                              <>
                                <span>합계</span>
                                <br />
                                <span>(VAT 포함)</span>
                              </>
                            )}
                          </div>
                        }
                        body={({
                          checkPrice,
                          checkPriceVat,
                          discountedCheckPrice,
                          insurancePrice,
                          insurancePriceVat,
                        }) => (
                          <div>
                            {showDetail ? (
                              <>
                                <div className="flex justify-content-between">
                                  <span className="mr-3">점검료+보험료</span>
                                  <span>
                                    {priceFormatter.format(
                                      checkPrice + insurancePrice
                                    )}
                                  </span>
                                </div>
                                <div className="flex justify-content-between">
                                  <span className="mr-3">VAT</span>
                                  <span>
                                    +{' '}
                                    {priceFormatter.format(
                                      checkPriceVat + insurancePriceVat
                                    )}
                                  </span>
                                </div>
                                <div className="flex justify-content-between">
                                  <span className="mr-3">할인</span>
                                  <span>
                                    -{' '}
                                    {priceFormatter.format(
                                      discountedCheckPrice
                                    )}
                                  </span>
                                </div>
                                <hr className="p-0 m-0" />
                                <div className="flex justify-content-between">
                                  <span className="mr-3">합계</span>
                                  <strong>
                                    {priceFormatter.format(
                                      checkPrice +
                                        checkPriceVat +
                                        insurancePrice +
                                        insurancePriceVat -
                                        discountedCheckPrice
                                    )}
                                  </strong>
                                </div>
                              </>
                            ) : (
                              <div className="text-right">
                                <span>
                                  {priceFormatter.format(
                                    checkPrice +
                                      checkPriceVat +
                                      insurancePrice +
                                      insurancePriceVat -
                                      discountedCheckPrice
                                  )}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      />
                      <Column
                        field="paidPrice"
                        className="text-right"
                        header="결제금액"
                        body={({
                          checkPricePaidYn,
                          checkPrice,
                          checkPriceVat,
                          discountedCheckPrice,
                          insurancePrice,
                          insurancePriceVat,
                          insurancePricePaidYn,
                        }) =>
                          checkPricePaidYn === 'Y' &&
                          insurancePricePaidYn === 'Y'
                            ? priceFormatter.format(
                                checkPrice +
                                  checkPriceVat +
                                  insurancePrice +
                                  insurancePriceVat -
                                  discountedCheckPrice
                              )
                            : checkPricePaidYn === 'N' &&
                              insurancePricePaidYn === 'Y'
                            ? priceFormatter.format(
                                insurancePrice + insurancePriceVat
                              )
                            : checkPricePaidYn === 'Y' &&
                              insurancePricePaidYn === 'N'
                            ? priceFormatter.format(
                                checkPrice +
                                  checkPriceVat -
                                  discountedCheckPrice
                              )
                            : 0
                        }
                      />
                      <Column
                        field="unpaidPrice"
                        className="text-right"
                        header="미결제금액"
                        body={({
                          checkPricePaidYn,
                          checkPrice,
                          checkPriceVat,
                          discountedCheckPrice,
                          insurancePrice,
                          insurancePriceVat,
                          insurancePricePaidYn,
                        }) =>
                          checkPricePaidYn === 'N' &&
                          insurancePricePaidYn === 'N'
                            ? priceFormatter.format(
                                checkPrice +
                                  checkPriceVat +
                                  insurancePrice +
                                  insurancePriceVat -
                                  discountedCheckPrice
                              )
                            : checkPricePaidYn === 'Y' &&
                              insurancePricePaidYn === 'N'
                            ? priceFormatter.format(
                                insurancePrice + insurancePriceVat
                              )
                            : checkPricePaidYn === 'N' &&
                              insurancePricePaidYn === 'Y'
                            ? priceFormatter.format(
                                checkPrice +
                                  checkPriceVat -
                                  discountedCheckPrice
                              )
                            : 0
                        }
                      />
                      <Column
                        field="isRecheck"
                        header="점검구분"
                        className="text-center"
                        body={({ isRecheck }) => (
                          <Tag
                            value={isRecheck === YN.YES ? '재성능' : '신규성능'}
                            className={classNames('text-sm', {
                              'bg-cyan-400': isRecheck !== YN.YES,
                            })}
                          />
                        )}
                      />
                      <Column
                        field="inspectorName"
                        header="점검자"
                        body={({
                          inspectorId,
                          inspectorLoginId,
                          inspectorName,
                        }) => (
                          <span>
                            {inspectorName}
                            <br />[{inspectorLoginId}]
                          </span>
                        )}
                      />
                      <Column
                        field="shopId"
                        header="점검장"
                        body={({ shopId, shopCode, shopName }) => (
                          <span>
                            {shopName}
                            <br />[{shopCode}]
                          </span>
                        )}
                      />
                      <Column
                        field="enterpriseId"
                        header="점검업체"
                        body={({
                          enterpriseId,
                          enterpriseCode,
                          enterpriseName,
                        }) => (
                          <span>
                            {enterpriseName}
                            <br />[{enterpriseCode}]
                          </span>
                        )}
                      />
                      <Column
                        field="associationId"
                        header="협회"
                        body={({
                          associationId,
                          associationCode,
                          associationName,
                        }) => (
                          <span>
                            {associationName}
                            <br />[{associationCode}]
                          </span>
                        )}
                      />
                    </DataTable>
                  </div>

                  {showPayment && (
                    <PaymentDialog
                      visible
                      onHide={() => {
                        setCurrentPaymentInfo(null);
                        setShowPayment(false);
                      }}
                      data={currentPaymentInfo}
                      getData={getData}
                      searchConditions={searchConditions}
                      setCurrentPaymentInfo={setCurrentPaymentInfo}
                    />
                  )}
                  {showPaymentUpdate && (
                    <PaymentUpdateDialog
                      visible
                      onHide={() => {
                        setCurrentPaymentInfo(null);
                        setShowPaymentUpdate(false);
                      }}
                      data={currentPaymentInfo}
                      getData={getData}
                      searchConditions={searchConditions}
                      setCurrentPaymentInfo={setCurrentPaymentInfo}
                    />
                  )}

                  {showSmsMoal && (
                    <SendSMS
                      showSmsType="PAYMENT"
                      smsData={smsData}
                      setSmsData={setSmsData}
                      customerInfo={customerInfo}
                      onHide={() => setShowSmsModal(!showSmsMoal)}
                      postSms={postSms}
                    />
                  )}
                </>
              );

            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(PaymentList, comparisonFn);
