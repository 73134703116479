import React, { useState, useCallback, useRef } from 'react';
import { Button } from 'primereact/button';

export default function CopyButton({ text }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = useCallback(() => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 500);
      })
      .catch((err) => {
        console.error('클립보드 복사에 실패했습니다:', err);
      });
  }, [text]);

  return (
    <>
      {isCopied ? (
        <Button
          icon="pi pi-check"
          aria-label="Copied"
          className="p-button-text"
        />
      ) : (
        <Button
          icon="pi pi-clone"
          aria-label="Copy"
          className="p-button-text"
          tooltip="복사"
          onClick={handleCopy}
        />
      )}
    </>
  );
}
