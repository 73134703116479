import React, { useEffect, useState } from 'react';
import * as Modal from '../Atoms/Modal';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import { CommonUtil } from '../../utils/commonUtil';
import Claim from '../../services/ClaimService';
import { Dropdown } from 'primereact/dropdown';
import dayjs from 'dayjs';
import { Tooltip } from 'primereact/tooltip';
import { ToggleButton } from 'primereact/togglebutton';
import { ServiceProvider } from '../../services';

const performanceCheckService = ServiceProvider.performanceCheck;

const WarrantyDiagnosis = ({
  detailData,
  carInfo,
  setWarrantyData,
  warrantyData,
  partnerList,
  setShowSmsType,
  setShowSmsModal,
  setSmsData,
  smsData,
  checked,
  onToggle,
  selectedPartner,
  setSelectedPartner,
  registerClaim,
  customerInfo,
  handleInput,
  postPushMessage,
  pushLoading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const today = new Date();
  const [partnerText, setPartnerText] = useState('');
  const [holidays, setHolidays] = useState(0);

  const differenceInDays = (day1, day2) => {
    const date1 = new Date(day1);
    const date2 = new Date(day2);
    const differenceInMillis = Math.abs(date2 - date1);
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const differenceInDays = Math.floor(
      differenceInMillis / millisecondsPerDay
    );

    return differenceInDays - holidays;
  };

  const WorkDetailReport = () => {
    return (
      <>
        <Modal.Default
          header={`정비이력 상세`}
          onHide={() => setShowModal(!showModal)}
        >
          {carInfo?.workDetails.length > 0
            ? carInfo?.workDetails?.map((work, idx) => (
                <div key={idx} className="flex w-full border-1 mb-2">
                  <div className="border-right-1 p-3  surface-100">
                    <div>
                      수리 요청일 :{' '}
                      {dayjs(work.workRequestDate).format('YYYY-MM-DD')}
                    </div>
                    <div>
                      수리 완료일 :{' '}
                      {dayjs(work.workCompleteDate).format('YYYY-MM-DD')}
                    </div>
                  </div>
                  <div className=" p-3">
                    {work.workItems.map((item, idx) => (
                      <div key={idx}>{item.fullText}</div>
                    ))}
                  </div>
                </div>
              ))
            : '이력이 없습니다.'}
        </Modal.Default>
      </>
    );
  };

  useEffect(() => {
    setWarrantyData((ps) => ({ ...ps, selectedPartner: selectedPartner }));
  }, [selectedPartner]);

  const getWorkDetailData = async (statementNumber) => {
    const { data } =
      await performanceCheckService.getDataMobileByStatementNumber(
        statementNumber
      );

    if (!!data && !!data?.workDetails?.length) {
      return (carInfo.workDetails = data.workDetails);
    }
  };

  useEffect(() => {
    if (!!carInfo) {
      if (carInfo.statementNumber.slice(0, 1).includes('7')) {
        getWorkDetailData(carInfo.statementNumber);
      }
      setWarrantyData((ps) => ({
        ...ps,
        producingCountry: carInfo.producingCountry,
        // checkDistance: carInfo.TRVL_DSTNC,
        carName: carInfo.cnm,
      }));
    }
  }, [carInfo]);

  const holidayList = async (startDate, endDate) => {
    try {
      const data = await Claim.getHolidays(startDate, endDate);
      const filteredList = data.filter(
        (el) => el.name !== '토요일' && el.name !== '일요일'
      );
      if (data) {
        setHolidays(filteredList.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (typeof warrantyData.buyDate !== 'string') {
      const startDate = dayjs(warrantyData.buyDate).format('YYYYMMDD');
      const endDatae = detailData.receiptDate
        ? detailData.receiptDate
        : dayjs(today).format('YYYYMMDD');

      if (startDate !== 'Invalid Date') {
        holidayList(startDate, endDatae);
      }
    }
  }, [detailData.receiptDate, today, warrantyData.buyDate]);

  const headerTemplate = (
    <div className="flex justify-content-between">
      <div className="flex align-items-center">4. 보증 / 거절 / 입고안내</div>
      <div className="flex gap-2">
        <Button
          label="성능지 보기"
          icon="pi pi-file"
          className=""
          onClick={() =>
            carInfo?.statementNumber
              ? CommonUtil.Window.popup(
                  `/performance-check/${carInfo.statementNumber}/viewer/mobile`,
                  `pcv#${carInfo.statementNumber}`,
                  window.innerWidth * 0.8,
                  window.innerHeight * 0.8
                )
              : null
          }
          disabled={
            !carInfo?.statementNumber.slice(0, 1).includes('7') &&
            !carInfo?.statementNumber.slice(0, 1).includes('6')
          }
        />
        <Button
          label="정비이력 보기"
          icon="pi pi-list"
          className=""
          onClick={() => setShowModal(!showModal)}
          disabled={!carInfo?.workDetails}
        />
        <ToggleButton
          checked={checked?.find((el) => el.status === 'Z05')?.value}
          onChange={(e) => onToggle('Z05', e.value)}
          onLabel="완료"
          offLabel="미확인"
          onIcon="pi pi-flag-fill"
          offIcon="pi pi-flag"
        />
      </div>
    </div>
  );

  const possible =
    warrantyData?.distanceDriven - warrantyData?.checkDistance < 2000;
  const errDistance =
    warrantyData?.distanceDriven - warrantyData?.checkDistance < 0;
  const dayPossible =
    warrantyData?.buyDate && !detailData.receiptDate
      ? differenceInDays(today, warrantyData.buyDate) < 31
      : warrantyData?.buyDate && detailData.receiptDate
      ? differenceInDays(
          dayjs(detailData.receiptDate, 'YYYYMMDD'),
          dayjs(warrantyData.buyDate, 'YYYYMMDD')
        ) < 31
      : '미입력';

  const [showDeleteModal, setShowDeleteModal] = useState({
    id: null,
    value: false,
  });

  const handleDeletePartner = (id) => {
    if (id) {
      const partner = selectedPartner.find((el) => el.partnerId === Number(id));
      if (!!partner.partnerDocuments?.length) {
        return setShowDeleteModal({
          id: Number(id),
          value: true,
        });
      } else {
        const list = selectedPartner.map((el) => {
          if (el.partnerId === Number(id)) {
            return { ...el, useYn: 'N' };
          }
          return el;
        });
        return setSelectedPartner(list);
      }
    }
  };

  const deletCompletPartner = (id) => {
    const list = selectedPartner.map((el) => {
      if (el.partnerId === Number(id)) {
        return { ...el, useYn: 'N' };
      }
      return el;
    });

    return setSelectedPartner(list);
  };

  const handleSelectPartner = (partner) => {
    if (selectedPartner.length > 4) {
      window.cerp.toast.warn('진단점은 최대 5개까지 등록 가능합니다.');
      return;
    }

    const isExistedPartner = selectedPartner.some(
      (el) => el.partnerId === partner.partnerId
    );
    if (isExistedPartner) {
      window.cerp.toast.warn('이미 선택된 진단점입니다.');
      return;
    }
    let newSelectPartner = {
      partnerId: partner.partnerId,
      partnerName: partner.partnerName?.split(' (')[0],
      address: partner.address,
      cellphone: partner.cellphone,
      useYn: 'Y',
      isOriginal: false,
      repairCodesName: partner.partnerName?.match(/\(([^)]+)\)/)[1],
      repairCodes: partner.partnerRepairType,
      partnerType: 'P_DR',
      partnerShip: partner.partnerShip,
    };

    setSelectedPartner([...selectedPartner, newSelectPartner]);
    setPartnerText(newSelectPartner);
  };

  useEffect(() => {
    if (partnerText) {
      setPartnerText('');
    }
  }, [partnerText]);

  return (
    <React.Fragment>
      <Panel header={headerTemplate} className="pt-3">
        <div className="grid">
          <div className="md:col-5 col-12 p-3">
            <div className="p-card-title field">보증 가능 여부 확인</div>
            <div className="field grid align-items-center">
              <div className="col-3">
                <div className="flex align-items-center gap-2">
                  <label className="font-bold" htmlFor="">
                    차량 인도일자
                  </label>
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
                    data-pr-tooltip="매매계약서의 인도일자"
                    data-pr-position="top"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
              <div className="col">
                <div className="flex align-items-center">
                  <Calendar
                    maxDate={today}
                    value={
                      typeof warrantyData.buyDate === 'string' &&
                      !!warrantyData.buyDate
                        ? CommonUtil.Formatter.stringToDate(
                            warrantyData.buyDate
                          )
                        : !!warrantyData.buyDate
                        ? warrantyData.buyDate
                        : null
                    }
                    onChange={(e) =>
                      setWarrantyData((ps) => ({
                        ...ps,
                        buyDate: e.target.value,
                      }))
                    }
                    dateFormat="yy-mm-dd"
                    showButtonBar
                    showIcon
                    mask="9999-99-99"
                    placeholder="인도일자 선택"
                  />
                  <div className="flex align-items-center gap-2">
                    <span className="ml-2">{`(경과일수: ${
                      warrantyData?.buyDate && !detailData.receiptDate
                        ? differenceInDays(today, warrantyData.buyDate)
                        : warrantyData?.buyDate && detailData.receiptDate
                        ? differenceInDays(
                            dayjs(detailData.receiptDate, 'YYYYMMDD'),
                            dayjs(warrantyData.buyDate, 'YYYYMMDD')
                          )
                        : ''
                    } 일)`}</span>
                    <Tooltip target=".custom-target-icon" />
                    <i
                      className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
                      data-pr-tooltip="공휴일 제외한 경과일수"
                      data-pr-position="top"
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field grid align-items-center">
              <div className="col-3">
                <label className="font-bold" htmlFor="">
                  성능시 주행거리
                </label>
              </div>
              <div className="col">
                <InputText
                  value={
                    !!warrantyData.checkDistance
                      ? warrantyData.checkDistance
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          ?.toString()
                      : ''
                  }
                  className=" w-full"
                  onChange={(e) =>
                    setWarrantyData({
                      ...warrantyData,
                      checkDistance: e.target.value.replaceAll(',', ''),
                    })
                  }
                  placeholder="성능시 주행거리 입력"
                />
              </div>
            </div>
            <div className="field grid align-items-center">
              <div className="col-3">
                <label className="font-bold" htmlFor="">
                  접수시 주행거리
                </label>
              </div>
              <div className="col">
                <InputText
                  value={
                    !!warrantyData?.distanceDriven
                      ? warrantyData?.distanceDriven
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          ?.toString()
                      : ''
                  }
                  onChange={(e) =>
                    setWarrantyData((ps) => ({
                      ...ps,
                      distanceDriven: e.target.value.replaceAll(',', ''),
                    }))
                  }
                  className=" w-full"
                  placeholder="접수시 주행거리 입력"
                />
              </div>
            </div>
            <div className="field grid align-items-center">
              <div className="col-3">
                <label className="font-bold" htmlFor="">
                  차량가액
                </label>
              </div>
              <div className="col">
                <InputText
                  value={
                    customerInfo?.vehicleValue
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      ?.toString() || ''
                  }
                  onChange={(e) => handleInput(e)}
                  className=" w-full"
                  placeholder="차량가액 입력"
                  name="vehicleValue"
                />
              </div>
            </div>
            <div className="field grid ">
              <div className="col-3 font-bold">
                <div>가능여부</div>
              </div>
              <div className="col text-left">
                <div className="">
                  {!possible || !dayPossible || errDistance ? (
                    <Tag severity="danger" value="불가능"></Tag>
                  ) : (
                    <Tag severity="success" value="가능"></Tag>
                  )}
                </div>
                {errDistance && (
                  <div className="mt-1">
                    접수시 주행거리가 성능시 주행거리보다 작습니다.
                  </div>
                )}
                {!dayPossible && (
                  <div className="mt-1">
                    인도일자로부터 30일이 넘어 보증이 되지 않습니다.
                  </div>
                )}
                {!possible && (
                  <div className="mt-1">
                    주행거리가 2000Km가 넘어 보증이 되지 않습니다.
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-content-center">
              <Button
                label="거절사유 전송"
                icon="pi pi-comment"
                className="p-button-outlined mr-1"
                onClick={(e) => {
                  setShowSmsModal(true);
                  setShowSmsType('REJECTION');
                  setSmsData({
                    ...smsData,
                    warrnaty: { day: dayPossible, distance: possible },
                  });
                }}
              />
            </div>
          </div>

          <div className="md:col-7 col-12 md:border-left-1 border-left-0 border-gray-300 p-3 ">
            <div className="p-card-title field">입고안내</div>
            <div className="field grid align-items-center">
              <div className="md:col-2 col-3">
                <label className="font-bold" htmlFor="">
                  국산 / 수입
                </label>
              </div>
              <div className="col">
                <Dropdown
                  className="w-full"
                  placeholder=""
                  options={PRODUCING_COUNTRY}
                  onChange={(e) =>
                    setWarrantyData((ps) => ({
                      ...ps,
                      producingCountry: e.value,
                    }))
                  }
                  value={warrantyData.producingCountry || 'D'}
                />
              </div>
            </div>
            <div className="field grid align-items-center">
              <div className="md:col-2 col-3">
                <label className="font-bold" htmlFor="">
                  차량명
                </label>
              </div>
              <div className="col">
                <InputText
                  onChange={(e) =>
                    setWarrantyData((ps) => ({
                      ...ps,
                      carName: e.target.value,
                    }))
                  }
                  value={warrantyData.carName || ''}
                  className=" w-full"
                  id="carName"
                  placeholder="차량명 입력"
                />
              </div>
            </div>

            <div className="field grid align-items-center">
              <div className="md:col-2 col-3">
                <label className="font-bold" htmlFor="">
                  입고점 선택
                </label>
              </div>
              <div className="col">
                <div className="col grid gap-2">
                  <Dropdown
                    className="md:col-3 col-5"
                    placeholder="반경(km)"
                    options={[5, 10, 15, 20, 25, 30, 40, 50, '전체']}
                    onChange={(e) =>
                      setWarrantyData((ps) => ({
                        ...ps,
                        radius: e.target.value,
                      }))
                    }
                    value={warrantyData.radius || ''}
                  />
                  <Dropdown
                    className="col"
                    value={partnerText}
                    onChange={(e) => handleSelectPartner(e.value)}
                    options={partnerList}
                    optionLabel="partnerName"
                    filter
                    placeholder="반경 선택 후 입고점을 선택해주세요."
                    emptyMessage="조건에 맞는 입고점이 없습니다."
                  />
                </div>
              </div>
            </div>
            <div className="field grid align-items-center col-12 w-full pr-0">
              {!!selectedPartner.length && (
                <div className="md:col-2 col">
                  <label className="font-bold" htmlFor="">
                    입고점명
                  </label>
                </div>
              )}
              <div className="md:col-10 col align-items-center pl-1">
                {!!selectedPartner.length &&
                  selectedPartner.map(
                    (el) =>
                      el.useYn === 'Y' && (
                        <div
                          key={el.partnerId}
                          className="md:flex block mb-3 gap-2"
                        >
                          <InputText
                            className="md:col-5 col p-2"
                            value={el.partnerName || ''}
                          />
                          <InputText
                            className="md:col-4 col p-2"
                            value={el.repairCodesName || ''}
                            placeholder="진단부위"
                          />
                          <InputText
                            className="md:col-2 col p-2"
                            value={
                              el.partnerShip === 'Y' ? '제휴' : '비제휴' || ''
                            }
                            placeholder="제휴여부"
                          />

                          <div
                            className="cursor-pointer flex justify-content-center align-items-center"
                            id={el.partnerId}
                            onClick={(e) => handleDeletePartner(e.target.id)}
                          >
                            <i
                              id={el.partnerId}
                              className="pi pi-minus-circle"
                            />
                          </div>
                        </div>
                      )
                  )}
              </div>
            </div>

            <div className="flex justify-content-center pt-2 gap-3">
              <Button
                label="고객 앱푸시"
                icon="pi pi-mobile"
                className="p-button-outlined mr-1"
                onClick={() => {
                  postPushMessage('C');
                }}
                loading={pushLoading}
              />

              <Button
                label="고객 입고점 안내 전송"
                icon="pi pi-user"
                className="p-button-outlined mr-1"
                onClick={() => {
                  setShowSmsModal(true);
                  setShowSmsType('INFO_TO_CUSTOMER');
                  setSmsData({
                    ...smsData,
                    partnerInfo: selectedPartner,
                  });
                  registerClaim('수정');
                }}
              />
              <Button
                label="점검업체 고객방문 전송"
                icon="pi pi-car"
                className="p-button-outlined mr-1"
                onClick={() => {
                  setShowSmsModal(true);
                  setShowSmsType('INFO_TO_PARTNER');
                  setSmsData({
                    ...smsData,
                    partnerInfo: selectedPartner,
                  });
                  registerClaim('수정');
                }}
              />
            </div>
            <div className="mt-4 flex align-items-center gap-2 ml-2">
              <i className="pi pi-exclamation-circle" />
              입고점 선택완료 시 저장 후 앱푸시를 보내주세요.
            </div>
          </div>
        </div>
      </Panel>
      {showModal && <WorkDetailReport />}
      {showDeleteModal.value && (
        <Modal.Default
          onHide={() =>
            setShowDeleteModal({
              id: null,
              value: false,
            })
          }
          header={'점검업체 삭제'}
          widthClass="30rem"
        >
          <div className="pb-4">
            해당 점검업체에 등록된 사진이 있습니다.
            <br />
            그래도 삭제하시겠습니까?
            <br />
            <br />
            <strong>*삭제 후 저장 시 최종 삭제됩니다.</strong>
          </div>
          <div className="flex gap-4 justify-content-center	">
            <Button
              label="삭제"
              className=" p-button-danger p-button-outlined"
              onClick={() => {
                deletCompletPartner(showDeleteModal.id);
                setShowDeleteModal({
                  id: null,
                  value: false,
                });
              }}
            />
            <Button
              label="취소"
              className=""
              onClick={() =>
                setShowDeleteModal({
                  id: null,
                  value: false,
                })
              }
            />
          </div>
        </Modal.Default>
      )}
    </React.Fragment>
  );
};

export default WarrantyDiagnosis;

const PRODUCING_COUNTRY = [
  { label: '국산', value: 'D' },
  { label: '수입', value: 'F' },
];

// const PARTNER_TYPE = [
//   { label: '공통', value: 'P_DR' },
//   { label: '진단', value: 'P_D' },
//   { label: '수리', value: 'P_R' },
// ];
